import React, { useRef, useState } from "react";
import { Box, Button, Slide, Grow, styled, Menu, SvgIcon } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';

import IconButton from "../ConfigurationComp/Utility/IconButtonComp";


const Marker = () => {

    return (
        <Box

            sx={{
                position: 'absolute',
                width: "16px",
                right: "0",
                top: "0",
                transform: "scale(1)",
                transition: "transform 0.2s",
                backgroundColor: "white",
                borderRadius: "100px"
            }}
            component="img"
            src="./assets/images/icons/CheckCircle.svg"
        />
    )

}


function SceneComp(props) {

    // const [open, setOpen] = useState(true)
    const containerRef = React.useRef(null);
    const open = props.open

    const [scene, setScene] = useState("forest")

    const anchorRef = useRef()


    const handleClose = (value) => {
        if (open) {
            props.setFeatureMode(null)
        } else {
            props.setFeatureMode("scene")
        }
    }

    const switchSceneHandler = (value) => {

        setScene(value)
        props.threejsModule.switchBackground(value)
        // switchBackground

    }




    return (
        <Grow
            in={true}
            style={{ transformOrigin: '0 0 0' }}
            timeout={200}
        >
            <Box
                ref={anchorRef}
                sx={{
                }}
            >
                <Box

                    sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        gap: "10px",
                        alignItems: "center"



                    }}>
                    {!open &&

                        <IconButton
                            active={true}
                            onClick={handleClose}
                            component="./assets/images/icons/Scene.svg"
                        >
                        </IconButton>}

                    {open &&
                        <Box
                            onClick={handleClose}
                            sx={{
                                cursor: "pointer",
                                width: "48px",
                                height: "48px",
                                display: 'flex',
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                transition: "transform .3s",
                            }}
                        >
                            <Box
                                component='img'
                                src="./assets/images/icons/Close.svg"
                                sx={{
                                    width: "20px",
                                    height: "20px",
                                    padding: "8px",
                                    borderRadius: "100px",
                                    backgroundColor: "white",
                                    animation: "rotateIn 0.2s linear"
                                }}
                            >
                            </Box>

                        </Box>

                        // <IconButton
                        //     onClick={handleClose}
                        //     active={true}
                        //     component="./assets/images/icons/Close.svg"
                        // >
                        // </IconButton>
                    }
                    <Grow in={open}>
                        <Box
                            sx={{
                                position: "relative"
                            }}
                        >
                            <IconButton
                                onClick={() => { switchSceneHandler("beach") }}
                                active={true}
                                border={scene === "beach" ? true : false}

                                component="./assets/images/icons/Beach.svg"
                            >
                            </IconButton>
                            {scene === "beach" && <Marker />}
                        </Box>

                    </Grow>
                    <Grow
                        in={open}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(open ? { timeout: 200 } : {})}
                    >
                        <Box
                            sx={{
                                position: "relative"
                            }}
                        >
                            <IconButton
                                onClick={() => { switchSceneHandler("city") }}
                                active={true}
                                border={scene === "city" ? true : false}

                                component="./assets/images/icons/City.svg"
                            >

                            </IconButton>
                            {scene === "city" && <Marker />}
                        </Box>
                    </Grow>
                    <Grow
                        in={open}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(open ? { timeout: 400 } : {})}
                    >
                        <Box
                            sx={{
                                position: "relative"
                            }}
                        >
                            <IconButton
                                onClick={() => { switchSceneHandler("forest") }}
                                active={true}
                                border={scene === "forest" ? true : false}
                                component="./assets/images/icons/House.svg"
                            >

                            </IconButton>
                            {scene === "forest" && <Marker />}
                        </Box>
                    </Grow>
                </Box>
            </Box>
        </Grow>
    )
}

export default SceneComp


