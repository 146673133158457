import React, { useRef, useState } from "react";
import { Grow, Box, alpha, styled, Switch, Typography, Collapse, IconButton, Divider, TextField } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';


const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#000000",
        '&:hover': {
            backgroundColor: alpha("#000000", theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#000000",
    },
}));


const RimCompMobile = (props) => {
    const [color, setColor] = useState(props.color)


    const overAllClick = () => {
        if (props.mode !== props.type) {
            props.setMode(props.type)
        }
    }

    const colorUpdateHandler = (e) => {
        // console.log(e.target.value)
        // setColor(e.target.value);

        const input = e.target.value


        if (e.target.value.length < 7) {
            setColor(e.target.value)

        }

        if (input.length === 7) {
            if (input[0] === "#") {
                const data = {
                    material: props.type,
                    color: e.target.value
                }
                setColor(e.target.value)
                props.colorUpdateHandler(data)
            }
        } else if (input.length === 6) {
            const data = {
                material: props.type,
                color: `#${e.target.value}`
            }
            props.colorUpdateHandler(data)
        }
    }

    return (

        <Grow
            in={true}
            style={{ transformOrigin: '1 1 1' }}
        >
            <Box
                onClick={overAllClick}
                sx={{

                    padding: props.mode !== props.type ? "10px 12px" : "20px 12px",
                    boxShadow: props.mode === props.type ? "0px 0px 6px 3px #9f9e9e47" : "none",
                    borderRadius: "3px",
                    backgroundColor: props.mode === props.type ? "#383A3A" : "#F5F5F5",
                    margin: "5px 0",
                    borderRadius: props.mode === props.type ? "16px" : "52px",
                    overflow: "hidden",
                    position: "relative",
                    transitionProperty: "filter",
                    transitionDuration: " 0.5s",
                    cursor: props.mode !== props.type ? 'pointer' : "default",
                    '&:hover': {
                        filter: props.mode === props.type ? "none" : "brightness(90%)",
                    },
                }}
            >


                <Collapse
                    in={props.mode === props.type} timeout="auto" unmountOnExit>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                            marginTop: "15px",
                        }}
                    >
                        <Box
                            sx={{
                                color: "white",
                                marginRight: "auto",
                                fontFamily: 'Mona Sans',
                                fontSize: "0.8rem",
                                fontWeight: "600",
                            }}
                        >
                            Color
                        </Box>

                        <Box
                            sx={{
                                color: "#7F7F7F",
                                fontSize: "0.7rem",
                                display: "flex",
                                alignItems: "center",
                                border: "2px solid #777777",

                            }}
                        >
                            <Typography
                                sx={{
                                    color: "white",
                                    padding: "1px 6px",
                                    fontWeight: "600",
                                    fontSize: "0.8rem",
                                    fontFamily: 'Mona Sans',
                                    backgroundColor: "#777777",

                                }}
                            >
                                HEX
                            </Typography>
                            <Box
                                onInput={colorUpdateHandler}
                                component="input"
                                value={color[0] === "#" ? color.substring(1) : color}
                                sx={{
                                    width: "70px",
                                    color: "white",
                                    fontWeight: "500",
                                    textAlign: 'center',
                                    borderRadius: "0",
                                    fontWeight: "600",
                                    border: "0px",
                                    boxShadow: "none",
                                    cursor: 'pointer',
                                    backgroundColor: "#ffffff00",
                                    textTransform: "uppercase",
                                    '&:focus': {
                                        border: "0px",
                                    }
                                }}
                            ></Box>
                        </Box>
                        <Box
                            sx={{
                                border: "0px solid #777777",
                                padding: "0",
                                backgroundColor: props.color,
                                width: "60px",
                                height: "24px",
                                borderRadius: "4px",
                                cursor: 'pointer',
                            }}
                        >
                            <input style={{
                                opacity: "0",
                                cursor: 'pointer',
                                width: "100%",
                            }} onChange={colorUpdateHandler} type="color" value={props.color} />

                        </Box>
                    </Box>

                </Collapse>

            </Box >
        </Grow>
    )

}


export default RimCompMobile
