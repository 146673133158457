import React, { useContext } from 'react';
import AIOContext from '../../AIOContext.js';
import './styles/FleetForm.css'; // Import the stylesheet
function ThankYouDAP() {
  const { user, vehicle } = useContext(AIOContext);
  const { vehicleConfig } = vehicle;

  return (
    <React.Fragment>
      <div className='form-panel !py-0 h-full'>
        <div className='flex flex-col justify-center gap-14 py-10 h-[-webkit-fill-available]'>
          <div className='flex justify-center'>
            <svg
              width='230'
              height='230'
              viewBox='0 0 230 230'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle
                opacity='0.1'
                cx='115.001'
                cy='115'
                r='91.7982'
                fill='#38B8AB'
              />
              <circle opacity='0.1' cx='115' cy='115' r='115' fill='#38B8AB' />
              <circle cx='115.002' cy='115' r='67.5877' fill='#38B8AB' />
              <circle cx='115' cy='115' r='49.4298' fill='white' />
              <g clipPath='url(#clip0_2701_18599)'>
                <path
                  d='M115 65.5701C87.7012 65.5701 65.5703 87.7017 65.5703 115.001C65.5703 142.301 87.7012 164.433 115 164.433C142.299 164.433 164.43 142.301 164.43 115.001C164.43 87.7017 142.299 65.5701 115 65.5701ZM142.066 106.685L111.797 136.954C109.8 138.952 106.564 138.952 104.567 136.954L87.9347 120.322C85.9373 118.324 85.9373 115.088 87.9347 113.091L89.2264 111.799C91.2239 109.801 94.4597 109.801 96.4572 111.799L108.182 123.524L133.543 98.1626C135.54 96.165 138.776 96.165 140.774 98.1626L142.065 99.4543C144.063 101.452 144.063 104.688 142.066 106.685Z'
                  fill='#38B8AB'
                />
              </g>
              <defs>
                <clipPath id='clip0_2701_18599'>
                  <rect
                    width='98.8596'
                    height='98.8596'
                    fill='white'
                    transform='translate(65.5703 65.5701)'
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div>
            <div className='text-2xl md:text-5xl font-black font-wide uppercase tracking-[0.03125rem] text-center'>
              DAP Purchased
            </div>
          </div>

          <div>
            <div className='flex flex-col text-center'>
              <div className='text-sm font-normal leading-normal tracking-wide mb-6'>
                Digital Asset Package - DAP purchase details sent to:
              </div>
              <div className='text-center text-neutral-900 text-sm font-semibold leading-normal tracking-wide font-wide mb-8'>
                {user.userConfig.emailAddress}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ThankYouDAP;
