import React, { useContext } from 'react';
import './styles/summaryStyles.css'; // Import the // Import the capitalizeFirstLetter function
import AIOContext from '../../AIOContext.js';
import formatCurrency from '../../util/formatCurrency.js';
import apiURL from '../../util/apiURL';
import { jsPDF } from 'jspdf';
import pdfmake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { isMobile } from 'react-device-detect';

function Confirmation({
  activatePurchaseDapForm,
  setLoading,
  setOnMobileDialogMode,
}) {
  const { user, dueAmount, dap, fleet, vehicle } = useContext(AIOContext);

  const { dapChecked, totalDapAmount } = dap;

  const { vehicleConfig } = vehicle;

  const { vehicleQuantities } = vehicleConfig;

  const { totalVehicles, totalVehicleTypesSelected, vehicleSubtotal } = fleet;

  const formatDocData = (obj) => {
    let result = [];

    function recurse(currentObj, prefix = '') {
      for (let key in currentObj) {
        if (currentObj.hasOwnProperty(key)) {
          let value = currentObj[key];
          let newKey = prefix ? `${prefix}.${key}` : key;

          if (
            typeof value === 'object' &&
            value !== null &&
            !Array.isArray(value)
          ) {
            recurse(value, newKey);
          } else {
            result.push([newKey, `${value}`]);
          }
        }
      }
    }

    recurse(obj);
    return result;
  };

  const formatVehicleBody = (obj) => {
    let result = [];

    for (const key in obj) {
      let childResult = [];
      if (obj.hasOwnProperty(key)) {
        childResult.push(key);
        const subObject = obj[key];
        for (const subKey in subObject) {
          if (subObject.hasOwnProperty(subKey)) {
            childResult.push(subObject[subKey]);
          }
        }
      }
      if (childResult[0] === 'body') {
        childResult[0] = 'Main Body';
        result[0] = childResult;
      } else if (childResult[0] === 'hScreen') {
        childResult[0] = 'Horizon Screen';
        result[10] = childResult;
      } else if (childResult[0] === 'trim') {
        childResult[0] = 'Window Trim';
        result[3] = childResult;
      } else if (childResult[0] === 'wallLower') {
        childResult[0] = 'Wall Lower';
        result[8] = childResult;
      } else if (childResult[0] === 'wall') {
        childResult[0] = 'Wall Upper';
        result[7] = childResult;
      } else if (childResult[0] === 'cover') {
        childResult[0] = 'AEROSHIELD™';
        result[4] = childResult;
      } else if (childResult[0] === 'roof') {
        childResult[0] = 'Roof';
        result[1] = childResult;
      } else if (childResult[0] === 'accent') {
        childResult[0] = 'Accent';
        result[2] = childResult;
      } else if (childResult[0] === 'rim') {
        childResult[0] = 'Rim';
        result[5] = childResult;
      } else if (childResult[0] === 'seat') {
        childResult[0] = 'Seat';
        result[9] = childResult;
      } else if (childResult[0] === 'floor') {
        childResult[0] = 'Floor';
        result[11] = childResult;
      } else if (childResult[0] === 'ceiling') {
        childResult[0] = 'Ceiling';
        result[6] = childResult;
      } else {
        childResult[0] =
          childResult[0].charAt(0).toUpperCase() + childResult[0].slice(1);
      }
      // result.push(childResult)
    }
    return result;
  };

  const printCurrentDate = () => {
    return new Date(user.userConfig.createdAt).toLocaleDateString(
      'en-US',
      {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'UTC',
      }
    )

  };

  const printUpdatedDate = () => {
    return new Date(user.userConfig.updatedAt).toLocaleDateString(
      'en-US',
      {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'UTC',
      }
    )
  };

  const generateSpecSheet = (user, carType) => {
    const { firstName, lastName, companyName } = user;
    const reservationDate = printCurrentDate();
    const reservationUpdatedDate = printUpdatedDate();

    let exteriorDimensions;
    let cargoSize;
    let rearDoorCount;
    let model;
    let isFleet;

    switch (carType) {
      case 'car':
        exteriorDimensions = "11'6' L x 5'6\" W x 5'3\" H";
        cargoSize = '17 ft3 = 2’6” L x 2.9" W x 2.9"H';
        rearDoorCount = 0;
        model = 'AIO CAR™';
        isFleet = false;
        break;
      case 'truck':
        exteriorDimensions = "12' L x 5'6\" W x 5'3\" H";
        cargoSize = '27 ft3 = 4\'6" L x 4\'0" W x 1\'6" H';
        rearDoorCount = 1;
        model = 'AIO TRUCK™';
        isFleet = false;
        break;
      case 'van':
        exteriorDimensions = "12'0' L x 5'6\" W x 5'9\" H";
        cargoSize = '63 ft3 = 4\'6" L x 4\'0" W x 3\'6" H';
        rearDoorCount = 2;
        model = 'AIO VAN™';
        isFleet = false;
        break;
      default:
        exteriorDimensions = '';
        cargoSize = '';
        model = 'AIO CAR™, AIO TRUCK™, AIO VAN™';
        isFleet = true;
        break;
    }

    const standardData = {
      Customer: `${firstName} ${lastName}`,
      ...(companyName && { 'Company Name': companyName }),
      Seller: 'AIO FINANCE',
      'Reservation Date': reservationDate,
      'Last Updated': reservationUpdatedDate,
      'Order Date': 'TBD',
      'Production Date': 'TBD',
      'Delivery Date': 'TBD',
      'Delivery Location': 'TBD',
      'Vehicle Identification Number': 'TBD',
      Model: model,
      'Human Capacity': 'One',
      Planet: 'Earth',
      'Primary Use': 'Ground',
      'Part Count Total': '14,308',
      'Part Count Unique Total': 'TBD',
      'Safety Rating': '5 Star',
      'Range Real World Average': '249 Miles',
      'Range Certified Combined': 'TBD',
      'Range Certified City': 'TBD',
      'Range Certified Highway': 'TBD',
      'Charge Time': '4 hours @ 7.2kW, 240v {30a)',
      Motor: 'Single',
      'Motor Type':
        'Synchronous Reluctance Internal Permanent Magnet Brushless AC Induction Electric',
      'Motor Voltage': 'Low',
      'Motor Cooling': 'Air',
      'Motor Controller Amp Max': '750A',
      'Motor Controller Cooling': 'Liquid',
      'Motor Efficiency Max': '95%',
      'Motor Power Max': '150 hp (112kW)',
      'Motor Torque Max': '173 lbs-ft (235 nm)',
      'Transmission Ratio': '8:1',
      'Transmission Cooling': 'Liquid',
      Drive: 'Rear',
      '0-60 mph': '5.9 seconds',
      'Speed Max': '85 mph',
      '60-0 mph braking (dry)': '100 ft',
      '60-0 mph braking (wet)': 'TBD',
      'Battery Size': '33.6+kwh',
      'Battery Recycle Percent': 'TBD',
      'Battery USA Made Percent': 'TBD',
      'Battery Cell Chemistry': 'Nickel Cobalt Aluminum',
      'Battery Cell Arraignment': '74P6S',
      'Battery Cell Total': '2,664',
      'Batter Cell Type': 'Cylindrical',
      'Battery Cooling': 'Liquid',
      'Batter Charger Power': '7.2 kw AC, TBD DC',
      'Batter Charger Input Voltage': '120/240 VAC, TBD VDC',
      'Battery Charger Input Amperage': '30 AAC, TBD ADC',
      'Batter Charger Connector': 'NACS, CCS',
      'Battery Charger Cooling': 'Liquid',
      'Inverter Power': '1.2 kW',
      'Inverter Output Voltage': '120 VAC',
      'Inverter Output Amperage': '10 AAC',
      'Inverter Output Plug': 'Type B',
      'High Voltage Wiring': 'Copper',
      'Low Voltage Wiring': 'Copper',
      'Vehicle Intelligence Communications':
        'AIO TELECOM™ A2XX™ CAN-BUS, USB-C, UWB, UL-BLE, NFC, Wi-Fi, Radar, LAN, Radio, Satellite',
      'Vehicle Intelligence Processor':
        'AIO INDUSTRIAL™ AVIP™ CBA with 4GB RAM',
      'Vehicle Intelligence Software': 'AIO SOFTWARE™ AVOS™ 1.0 Linux based',
      'Vehicle Intelligence Hardware':
        'AIOSENSE™ 1.0 Rearview HD camera, Interior HD camera, full surround exterior HD cameras, Backup HD camera, Occupant Health Sensor, Ambient Environment Sensor, Vehicle Dynamics Sensor, Vehicle Health Sensor',
      Radio: 'Optional',
      Satellite: 'Optional',
      Lidar: 'Optional',
      ADAS: 'Optional',
      'Vehicle Intelligence Security': 'Active',
      'Vehicle Intelligence Drive Modes': 'Customizable with upload/download',
      'Exterior Dimensions': exteriorDimensions || 'exterior dimensions',
      'Cargo Size': cargoSize || 'cargo size',
      'Weight Gross (GVWR)': 'TBD',
      'Weight Combined (GCWR)': 'TBD',
      'Weight Max Payload': 'TBD',
      'Weight Max Tongue': 'TBD',
      Wheel: 'Four',
      'Wheel Base (CTC)': '91.1"',
      'Wheel Track Width Front (CTC)': '55"',
      'Wheel Track Width Rear (CTC)': '55"',
      'Ground Clearance': '6"',
      'Approach Angle': 'TBD',
      'Departure Angle': 'TBD',
      'Frame Architecture': 'Spaceframe',
      'Frame Materials': 'AIO RECYCLE™ Mixed Steel',
      'Body Architecture': 'Unibody',
      'Body Material': 'TBD',
      'Paint E Coat Base': 'Water',
      'Paint Color Base': 'Water',
      'Paint Top (Clear) Base': 'Water',
      'Corrosion Rating': 'C5',
      Windshield: 'Acoustic',
      'Windshield Electric Heat': 'Optional',
      'Door Left': 'Yes',
      'Door Left Window': 'Glass Standard',
      'Door Left Handle': 'Electric',
      'Door Left Check Strap': 'Standard',
      'Door Right': 'Yes',
      'Door Right Window': 'Glass Standard',
      'Door Right Handle': 'Electric',
      'Door Right Check Strap': 'Standard',
      'Door Rear': rearDoorCount,
      'Door Window Control': 'Power up/down',
      'Door Mirrors Side View': 'Digital display',
      Roof: 'Hard',
      'Lights Head': 'AIOLIGHT™ Laser - USA Spec',
      'Lights Fog': 'AIOLIGHT™ Laser - USA Spec',
      'Lights Tail': 'AIOLIGHT™ Laser - USA Spec',
      Reflectors: 'Orange- USA Spec',
      'Accelerator Pedal': 'Standard',
      'Accelerator System': 'Wire',
      'Wheel Size': '20"',
      'Tire Size': '245/50/R20',
      'Tire Type': 'TBD',
      'Tire Speed Rating': 'TBD',
      'Brake Pedal': 'Standard',
      'Brake System': 'Standard - USA Spec',
      'Brakes Front System': 'Hydraulic',
      'Brakes Rotor Front': '10” x 2” Cast Iron',
      'Brakes Caliper Front': '2" Dual Piston',
      'Brake Pads Front': 'USA Spec',
      'Brakes Rear System': 'Hydraulic',
      'Brakes Rotor Rear': '10” x 1” Cast Iron',
      'Brakes Caliper Rear': '2" Dual Piston',
      'Brakes Pads Rear': 'USA Spec',
      'Brakes Anti-lock Brake System (ABS)': 'Active',
      'Brakes Electronic Park': 'Standard',
      'Brakes Regenerative Engergy': 'Adjustable',
      'Electronic Stability Control System (ESC)': 'Active',
      'Electronic Traction Control System (TSC)': 'Active',
      'Chassis Electronic Control Settings': 'Adjustable',
      'Chassis Ride Height': 'Standard',
      'Chassis Ride Settings': 'Adjustable',
      'Chassis Handling Settings': 'Adjustable',
      'Chassis Control Arms Front': 'Miltilink Steel',
      'Chassis Suspension Front': 'Independent McPherson strut',
      'Chassis Stabilizer Bar Front': 'No',
      'Chassis Stabilizer Bar Rear': 'No',
      'Chassis Steering Front': 'Ball Screw with Electronic Power Assist',
      'Chasses Steering Rear': 'No',
      'Steering Wheel': 'Miltiway electronic adjustable with memory',
      'Steering Wheel Shape': 'Square Circle',
      'Steering Wheel Controls': 'Front: up/down, forward/back Back: trigger',
      Display: '28.6” UWQHD touch display',
      'Display Hand Preference': 'Right Hand',
      'Climate Heat Vent AirCon (HVAC)': 'AIOSPHERE™ climate control system',
      'Airbag Steering Wheel': 'Standard',
      'Airbag Knee': 'Standard',
      'Airbag Side Curtain Left': 'Standard',
      'Airbag Side Curtain Right': 'Standard',
      'Pedals Control': 'Multiway electronic adjustable with memory',
      'Seat Type': 'AIOSEAT™',
      'Seat Control': 'Miltiway electronic adjustable with memory',
      'Seat Headrest': 'Adjustable',
      'Seat Armrests': 'TBD',
      'Seat Belt Type': '3 point adjustable',
      'Seat Belt Mount Side': 'Left',
      Microphone: 'Single',
      'Microphone type': 'High SNR dynamic wide range',
      'Sound System': 'AIOSOUND™ High Definition Surround',
      'Sound Speakers': 'TBD',
      'Sound Amplifier': 'TBD',
      'Sound Wiring': 'Copper',
      'Sound Processing':
        'Multi EQ band Digital Signal Processing with Active Noise Cancellation',
      'Sound Exterior': 'Adjustable',
      'Sound Interior Loudness at 60 mph': '60 db',
      'Sound Exterior Loudness Max': '95 dBA',
      'Accessories Storage':
        'Bev-rest, door pockets, coat hook, purse hook, full size spare, tie downs, valuables, paper articles, firearms, digital assets.',
      'AIO STAR™ Package':
        'AIO STARCRAFT AIO STARFLEET AIO STARRACER AIO STARSAVER AIO STARFIGHTER AIO STARTRACKER AIO STARSHIP AIO STARSHOT',
      'Certification Global (WIP)':
        'SO, SAE, ASTM, ANSI, FMVSS, EPA, US-NCAP IIHS, FCC, IC, ISED, ICES, IFT NOM, UL, TISAX, IATF, EN, IEC, UNECE, CEN, CENELEC, ETSI, G-NCAP, EU-NCAP, VCA, SABS, SAC, CNCA, IEC, GB, C-NCAP, JNCAP, KNCAP, XNCAP, ASEAN, GCC, RTA, ARAI, B-NCAP, BIS, L-NCAP, ANCAP',
      'Final Assembly Location': 'USA',
      'Local Part Content %': 'TBD',
      'Carbon Embedded': '10 tonnes',
      'Carbon Footprint': '0.25 kg CO2e / mi',
    };

    // const html = process.env.NODE_ENV === 'development'
    //   ? await ejs.renderFile('/home/josh/Repo/aio-configure/server/util/specSheet.ejs', { standardData, vehicleData })
    //   : await ejs.renderFile('/home/ec2-user/server/server/util/specSheet.ejs', { standardData, vehicleData })

    return standardData;
  };

  const handleDownloadClick = (documentData) => {
    console.log('DOC DATA: ', documentData);
    pdfmake.vfs = pdfFonts.pdfMake.vfs;
    const { carType, vehicle } = documentData;
    const data = generateSpecSheet(user.userConfig, carType);

    const docData = formatDocData(data);
    const docVehicleData = formatVehicleBody(vehicle.colors);
    // console.log('DATA: ', docVehicleData)
    const docDefinition = {
      content: [
        {
          text: 'AIO™ SPECIFICATIONS SHEET',
          style: 'header',
        },
        {
          table: {
            headerRows: 0,
            widths: ['*', '*'],
            body: docData,
          },
        },
        {
          text: 'AIO CUSTOM™',
          style: 'header',
        },
        {
          table: {
            headerRows: 0,
            widths: ['*', '*', '*'],
            body: docVehicleData,
          },
        },
      ],
    };
    pdfmake
      .createPdf(docDefinition)
      .download(`AIO ${carType.toUpperCase()} Specifications Sheet.pdf`);
  };

  const vehicleTypesSelected = Object.keys(vehicleQuantities).reduce(
    (types, vehicleType) => {
      if (vehicleQuantities[vehicleType] > 0) {
        types.push(vehicleType);
      }
      return types;
    },
    []
  );

  const handleDapDownload = async () => {
    if (isMobile) {
      setOnMobileDialogMode(true);
    } else {
      const filename = vehicleConfig.isFleet
        ? 'AIO FLEET'
        : `AIO ${vehicleConfig.carType.toUpperCase()}`;

      window.dispatchEvent(
        new CustomEvent('webxr-render-image', {
          detail: {
            vehicles: {
              car: vehicleConfig.isFleet
                ? vehicleTypesSelected.includes('car')
                : vehicleConfig.carType === 'car',
              truck: vehicleConfig.isFleet
                ? vehicleTypesSelected.includes('truck')
                : vehicleConfig.carType === 'truck',
              van: vehicleConfig.isFleet
                ? vehicleTypesSelected.includes('van')
                : vehicleConfig.carType === 'van',
            },
            filename,
          },
        })
      );
    }
  };

  return (
    <React.Fragment>
      <div className='form-panel !pt-8'>
        <h2 className='font-wide text-3xl font-extrabold tracking-[0.03125rem] mb-8 pr-16'>
          Reservation Confirmation
        </h2>

        <hr />

        <div className='pb-6 pt-10 flex flex-col gap-6'>
          <div className='font-wide text-2xl font-extrabold'>
            Hi, {user.userConfig.firstName}!
          </div>
          <div className='text-neutral-900 text-base font-normal leading-normal tracking-wide'>
            Welcome to the first-class experience on Earth
          </div>
        </div>

        <hr />

        {/* Purchase Overview */}
        <div className='pt-10 pb-6'>
          <div className='font-wide text-xl md:text-2xl font-extrabold pb-6'>
            Reservation Details
          </div>

          <div className='px-6 py-10 bg-neutral-100 rounded-lg border border-zinc-100 gap-8 flex flex-col text-neutral-900'>
            <div className=''>
              <div className='font-wide text-xl md:text-2xl font-extrabold uppercase pb-3'>
                {vehicleConfig.isFleet
                  ? 'AIO Fleet'
                  : 'AIO ' + vehicleConfig.carType}
                {'™'}
              </div>
              <div className='text-neutral-900 text-sm font-normal leading-normal tracking-wide uppercase'>
                The Single-Occupant Electric{' '}
                {vehicleConfig.isFleet ? 'Fleet' : vehicleConfig.carType}
              </div>
            </div>

            {vehicleConfig.isFleet && (
              <div className='flex flex-col gap-2'>
                <div className='flex justify-between'>
                  <div className='text-[1.125rem] font-wide font-bold'>
                    Total Vehicles
                  </div>
                  <div className='text-[0.875rem] tracking-[0.0625rem] font-semibold font-wide'>
                    Quantity
                  </div>
                </div>
                <div className='flex justify-between items-center gap-6'>
                  <div className='flex gap-2'>
                    {vehicleQuantities.car > 0 && (
                      <div className='chip'>
                        {vehicleQuantities.car}{' '}
                        {vehicleQuantities.car === 1 ? 'Car' : 'Cars'}
                      </div>
                    )}
                    {vehicleQuantities.truck > 0 && (
                      <div className='chip'>
                        {vehicleQuantities.truck}{' '}
                        {vehicleQuantities.truck === 1 ? 'Truck' : 'Trucks'}
                      </div>
                    )}
                    {vehicleQuantities.van > 0 && (
                      <div className='chip'>
                        {vehicleQuantities.van}{' '}
                        {vehicleQuantities.van === 1 ? 'Van' : 'Vans'}
                      </div>
                    )}
                  </div>
                  <div className='text-[1.125rem] font-wide font-bold'>
                    {totalVehicles}
                  </div>
                </div>
              </div>
            )}

            <hr />

            <div className='flex flex-col'>
              <div className='flex justify-between'>
                <div className='md:flex items-center gap-2'>
                  <span className='text-md md:text-lg font-bold font-wide'>
                    Reservation
                  </span>
                  <br className='md:hidden' />
                  {!vehicleConfig.isFleet && (
                    <div className='text-small'>(Refundable)</div>
                  )}
                </div>
                <div className='text-lg font-bold font-wide'>
                  {formatCurrency(vehicleSubtotal)}
                </div>
              </div>
              {vehicleConfig.isFleet && (
                <div className='flex justify-between text-[0.75rem] font-normal tracking-[0.0625rem]'>
                  <div className=''>(Refundable)</div>
                  <div className=''>
                    Vehicle Quantity ({totalVehicles}) x $100ea
                  </div>
                </div>
              )}
            </div>

            <div>
              <div className='flex justify-between gap-2 text-md md:text-lg font-bold font-wide'>
                <div className='text-left'>Digital Asset Package - DAP</div>

                {dapChecked ? (
                  <div>{formatCurrency(totalDapAmount)}</div>
                ) : (
                  <div className='text-right'>Not Purchased</div>
                )}
              </div>
              {vehicleConfig.isFleet && (
                <div className='text-right text-[0.75rem] font-normal tracking-[0.0625rem]'>
                  Vehicle Types ({totalVehicleTypesSelected}) x $10ea
                </div>
              )}
            </div>

            <hr />
            <div className=''>
              <div className='flex justify-between gap-2  text-xl font-extrabold font-wide'>
                <div className='text-left'>Total</div>

                <div className='text-right'>
                  {formatCurrency(dueAmount)} USD
                </div>
              </div>
              <div className='flex flex-col gap-1'>
                <div className='text-right text-xs leading-normal tracking-wide'>
                  {/* date formatted like August 27, 2023 */}
                  Reservation made on{' '}
                  {user.userConfig.createdAt &&
                    new Date(user.userConfig.createdAt).toLocaleDateString(
                      'en-US',
                      {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                        timeZone: 'UTC',
                      }
                    )}
                </div>
                
              </div>
            </div>
          </div>
        </div>

        {dapChecked ? (
          <div className='pt-4'>
            <hr />

            <div className='flex justify-between gap-2 text-xl md:text-2xl font-extrabold tracking-wide font-wide pt-10'>
              <div className='text-left'>Digital Asset Package - DAP</div>
            </div>

            <div className='pb-10'>
              <div className='my-4 text-[0.75rem] font-normal tracking-[0.03125rem] leading-[1.125rem] text-[#383A3A]'>
                <div>AIO PICTURES™ artificially intelligent movie studio:</div>
                <ul className='my-0'>
                  <li>Ultra High Definition Images of AIO CUSTOM™</li>
                </ul>
              </div>

              <div className='flex justify-between items-center gap-2'>
                <button
                  className='bg-brand text-white py-3 px-[20px] rounded-[60px] uppercase tracking-[0.03125rem] leading-normal font-bold font-wide text-[0.75rem] cursor-pointer'
                  onClick={() => handleDapDownload()}
                >
                  Download Images
                </button>
                <button className='bg-brand text-white py-3 px-[20px] rounded-[60px] uppercase tracking-[0.03125rem] leading-normal font-bold font-wide text-[0.75rem] cursor-pointer'>
                  Short Movie <i className='font-normal'>Coming Soon!</i>
                </button>
              </div>
            </div>

            <hr />
          </div>
        ) : (
          <div className='pt-4'>
            <hr />
            <div className='flex justify-between gap-2 text-xl md:text-2xl font-extrabold tracking-wide font-wide pt-10'>
              <div className='text-left'>Digital Asset Package - DAP</div>
            </div>

            <div className='pb-10'>
              <div className='my-4 text-[0.75rem] font-normal tracking-[0.03125rem] leading-[1.125rem] text-[#383A3A]'>
                <div>AIO PICTURES™ artificially intelligent movie studio:</div>
                <ul className='my-0'>
                  <li>Ultra High Definition Images of AIO CUSTOM™</li>
                </ul>
              </div>

              <button
                className='bg-brand text-white py-3 px-[20px] rounded-[60px] uppercase tracking-[0.03125rem] leading-normal font-bold font-wide text-[0.75rem] cursor-pointer'
                onClick={() => activatePurchaseDapForm()}
              >
                Purchase - {formatCurrency(totalDapAmount)} USD
              </button>
            </div>

            <hr />
          </div>
        )}

        <div className='flex flex-col gap-6 pt-10 pb-8'>
          <div className='font-wide text-xl md:text-2xl font-extrabold'>
            Technical Specifications
          </div>
          <div className='text-neutral-900 text-sm font-normal leading-normal tracking-wide'>
            Vehicle details and custom information
          </div>
        </div>
        <div className="pb-10 flex flex-col gap-2">

        
        <div className='flex flex-col gap-4'>
          {(vehicleConfig.isFleet
            ? vehicleTypesSelected.includes('car')
            : vehicleConfig.carType === 'car') && (
            <div className='flex justify-between items-center gap-6'>
              <div className='text-neutral-900 text-base font-bold tracking-wide'>
                AIO CAR™
              </div>
              <div>
                <button
                  className='bg-brand text-white py-3 px-[20px] rounded-[60px] uppercase tracking-[0.03125rem] leading-normal font-bold font-wide text-[0.75rem] cursor-pointer inline-flex items-center gap-2 no-underline'
                  onClick={() =>
                    handleDownloadClick({
                      vehicle: vehicle.vehicleConfig,
                      carType: 'car',
                      user: user.userConfig,
                    })
                  }
                >
                  Download Specs
                </button>
              </div>
            </div>
          )}

          {(vehicleConfig.isFleet
            ? vehicleTypesSelected.includes('truck')
            : vehicleConfig.carType === 'truck') && (
            <div className='flex justify-between items-center gap-6'>
              <div className='text-neutral-900 text-base font-bold tracking-wide'>
                AIO TRUCK™
              </div>
              <div>
                <button
                  className='bg-brand text-white py-3 px-[20px] rounded-[60px] uppercase tracking-[0.03125rem] leading-normal font-bold font-wide text-[0.75rem] cursor-pointer inline-flex items-center gap-2 no-underline'
                  onClick={() =>
                    handleDownloadClick({
                      vehicle: vehicle.vehicleConfig,
                      carType: 'truck',
                      user: user.userConfig,
                    })
                  }
                >
                  Download Specs
                </button>
              </div>
            </div>
          )}

          {(vehicleConfig.isFleet
            ? vehicleTypesSelected.includes('van')
            : vehicleConfig.carType === 'van') && (
            <div className='flex justify-between items-center gap-6'>
              <div className='text-neutral-900 text-base font-bold tracking-wide'>
                AIO VAN™
              </div>
              <div>
                <button
                  className='bg-brand text-white py-3 px-[20px] rounded-[60px] uppercase tracking-[0.03125rem] leading-normal font-bold font-wide text-[0.75rem] cursor-pointer inline-flex items-center gap-2 no-underline'
                  onClick={() =>
                    handleDownloadClick({
                      vehicle: vehicle.vehicleConfig,
                      carType: 'van',
                      user: user.userConfig,
                    })
                  }
                >
                  Download Specs
                </button>
              </div>
            </div>
          )}
        </div>
        {
          user.userConfig.updatedAt &&
           (
            <div className='text-right text-xs leading-normal tracking-wide'>
              Last updated on{' '}
              {user.userConfig.updatedAt &&
                new Date(user.userConfig.updatedAt).toLocaleDateString(
                  'en-US',
                  {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    timeZone: 'UTC',
                  }
                )}
                </div>
          )}
        </div>

        <hr />

        <div className='terms pt-6 font-wide text-[12px] tracking-[0.03125rem]'>
          <a
            href='https://aiomotors.com/terms-of-use'
            target='_blank'
            rel='noreferrer'
            className='underline'
          >
            Terms of Use
          </a>
          {' | '}
          <a
            href='https://aiomotors.com/privacy-policy'
            target='_blank'
            rel='noreferrer'
            className='underline'
          >
            Privacy Policy
          </a>
          {' | '}
          <a
            href='https://aiomotors.com/reservation-agreement'
            target='_blank'
            rel='noreferrer'
            className='underline'
          >
            Reservation Agreement
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Confirmation;
