import CloseIcon from '@mui/icons-material/Close';
import { styled } from "@mui/material/styles";

const CloseComp = styled(CloseIcon)((props) => ({
    // Custom CSS
    cursor: "pointer",
    width: "15px",
    height: "15px",
    fontWeight: "200",
    padding: "10px",
    borderRadius: "50px",
    backgroundColor: "#F5F5F5",
    transitionProperty: "background-color, box-shadow,transform,filter",
    transitionDuration: " 0.5s",
    '&:hover': {
        transform: "scale(1.1)",
        filter: "invert(1)",
    },
}));

export default CloseComp
