const MaterialDataSet = [
    {
        title: "White",
        id: 0,
        preview: "./assets/materials/gloss.png",
        file: "./assets/materials/gloss.glb"
    },
    {
        title: "Black",
        id: 1,
        preview: "./assets/materials/black.png",
        file: "./assets/materials/black.glb"
    },
    {
        title: "Walnut",
        id: 0,
        preview: "./assets/materials/carpet.png",
        file: "./assets/materials/carpet.glb"
    },
    {
        title: "Oak",
        id: 1,
        preview: "./assets/materials/fabric_suit_vintage.png",
        file: "./assets/materials/fabric_suit_vintage.glb"
    },
    {
        title: "Grey",
        id: 2,
        preview: "./assets/materials/leather-wrap.png",
        file: "./assets/materials/leather-wrap.glb"
    },
    {
        title: "Bamboo",
        id: 3,
        preview: "./assets/materials/marblesubstance001.png",
        file: "./assets/materials/marblesubstance001.glb"
    },
    {
        title: "Tan",
        id: 4,
        preview: "./assets/materials/NaturalLeather.png",
        file: "./assets/materials/NaturalLeather.glb"
    },
    {
        title: "Black2",
        id: 5,
        preview: "./assets/materials/Pelletraforatachiara.png",
        file: "./assets/materials/Pelletraforatachiara.glb"
    },
    {
        title: "Driving",
        id: 5,
        preview: "./assets/materials/Plastique-brillant.png",
        file: "./assets/materials/Plastique-brillant.glb"
    }
]


export default MaterialDataSet