import React, { useContext, useState } from 'react';
import './styles/summaryStyles.css';
import formatCurrency from '../../util/formatCurrency.js';
import AIOContext from '../../AIOContext.js';
import { capitalizeFirstLetter } from '../../util/textUtils';

function Summary() {
  const { user, vehicle, dueAmount, dap, fleet } = useContext(AIOContext);

  const { vehicleConfig } = vehicle;

  const { vehicleQuantities } = vehicleConfig;

  const { dapChecked, toggleDap, totalDapAmount } = dap;

  const { totalVehicles, vehicleSubtotal, totalVehicleTypesSelected } = fleet;

  const [dapExpanded, setDapExpanded] = useState(false);

  return (
    <React.Fragment>
      <div className='form-panel'>
        <h2 className='font-wide text-2xl md:text-3xl font-extrabold tracking-[0.03125rem] mb-14'>
          Checkout
        </h2>

        <div className='pb-4'>
          <div className='font-wide text-xl md:text-2xl font-extrabold uppercase'>
            {vehicleConfig.isFleet
              ? 'AIO Fleet'
              : 'AIO ' + vehicleConfig.carType}
            {'™'}
          </div>
        </div>

        <div className='pb-10'>
          <div className='text-neutral-900 text-sm font-normal leading-normal tracking-wide uppercase'>
            The Single-Occupant Electric{' '}
            {vehicleConfig.isFleet ? 'Fleet' : vehicleConfig.carType}
          </div>
        </div>

        {vehicleConfig.isFleet && (
          <div className='flex flex-col gap-2 pb-6'>
            <div className='flex justify-between text-[1rem] md:text-[1.125rem] font-wide font-bold'>
              <div className=''>Total Vehicles</div>
              <div className=''>{totalVehicles}</div>
            </div>
            <div className='flex gap-2'>
              {vehicleQuantities.car > 0 && (
                <div className='chip'>
                  {vehicleQuantities.car}{' '}
                  {vehicleQuantities.car === 1 ? 'Car' : 'Cars'}
                </div>
              )}
              {vehicleQuantities.truck > 0 && (
                <div className='chip'>
                  {vehicleQuantities.truck}{' '}
                  {vehicleQuantities.truck === 1 ? 'Truck' : 'Trucks'}
                </div>
              )}
              {vehicleQuantities.van > 0 && (
                <div className='chip'>
                  {vehicleQuantities.van}{' '}
                  {vehicleQuantities.van === 1 ? 'Van' : 'Vans'}
                </div>
              )}
            </div>
          </div>
        )}

        <hr />

        {/* Purchase Overview */}
        <div className='pt-10'>
          <div className='font-wide text-xl md:text-2xl font-extrabold pb-6'>
            Purchase Overview
          </div>
          <div>
            <div className='flex flex-col gap-[10px]'>
              <div className='flex justify-between'>
                <div className=' flex items-center gap-2'>
                  <span className='text-[0.875rem] font-bold font-wide'>
                    Reservation
                  </span>
                  {!vehicleConfig.isFleet && (
                    <div className='text-small'>(Refundable)</div>
                  )}
                </div>
                <div className='text-[1.125rem] font-bold font-wide'>
                  {formatCurrency(vehicleSubtotal)}
                </div>
              </div>
              {vehicleConfig.isFleet && (
                <div className='flex justify-between text-[0.75rem] font-normal tracking-[0.0625rem]'>
                  <div className=''>(Refundable)</div>
                  <div className=''>
                    Vehicle Quantity ({totalVehicles}) x $100ea
                  </div>
                </div>
              )}
            </div>

            <div className='py-6 flex flex-col gap-5'>
              <div className='flex justify-between gap-6 items-center'>
                <button
                  className='appearance-none bg-transparent cursor-pointer flex gap-1 md:gap-2 justify-start items-center min-h-[36px] text-left'
                  onClick={() => setDapExpanded(!dapExpanded)}
                >
                  <div className='text-neutral-900 text-xs md:text-sm font-bold font-wide tracking-wide'>
                    Digital Asset Package - DAP
                  </div>
                  <svg
                    width='10'
                    height='10'
                    viewBox='0 0 10 10'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className={`${
                      dapExpanded ? 'transform rotate-180' : ''
                    } transition-transform shrink-0`}
                  >
                    <g clipPath='url(#clip0_3101_24678)'>
                      <path
                        d='M5.00186 8.1249C4.71019 8.1249 4.46019 7.9999 4.29353 7.79156L0.168527 3.16656C-0.0814732 2.83323 -0.0398065 2.33323 0.293527 2.08323C0.62686 1.83323 1.08519 1.83323 1.33519 2.16656L4.91853 6.2499C4.96019 6.29156 5.00186 6.29156 5.08519 6.2499L8.66853 2.16656C8.96019 1.83323 9.41853 1.79156 9.75186 2.08323C10.0852 2.3749 10.1269 2.83323 9.83519 3.16656L9.79353 3.20823L5.71019 7.83323C5.54353 7.9999 5.25186 8.1249 5.00186 8.1249Z'
                        fill='#9A9A9A'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_3101_24678'>
                        <rect width='10' height='10' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                </button>

                {dapChecked ? (
                  <div className='flex flex-col gap-[10px] items-end'>
                    <div className='flex items-center gap-4'>
                      <div>
                        <button
                          className='appearance-none bg-transparent cursor-pointer flex items-center gap-[6px] py-[6px]'
                          onClick={() => toggleDap()}
                        >
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              width='24'
                              height='24'
                              rx='12'
                              fill='#F9F2F1'
                            />
                            <path
                              d='M15.75 10.5H8.25C8.1837 10.5 8.12011 10.5263 8.07322 10.5732C8.02634 10.6201 8 10.6837 8 10.75V17C8 17.2652 8.10536 17.5196 8.29289 17.7071C8.48043 17.8946 8.73478 18 9 18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17V10.745C15.9987 10.6796 15.9718 10.6173 15.925 10.5714C15.8783 10.5256 15.8154 10.5 15.75 10.5ZM11.125 16.25C11.125 16.3495 11.0855 16.4448 11.0152 16.5152C10.9448 16.5855 10.8495 16.625 10.75 16.625C10.6505 16.625 10.5552 16.5855 10.4848 16.5152C10.4145 16.4448 10.375 16.3495 10.375 16.25V11.935C10.375 11.8355 10.4145 11.7402 10.4848 11.6698C10.5552 11.5995 10.6505 11.56 10.75 11.56C10.8495 11.56 10.9448 11.5995 11.0152 11.6698C11.0855 11.7402 11.125 11.8355 11.125 11.935V16.25ZM13.625 16.25C13.625 16.3495 13.5855 16.4448 13.5152 16.5152C13.4448 16.5855 13.3495 16.625 13.25 16.625C13.1505 16.625 13.0552 16.5855 12.9848 16.5152C12.9145 16.4448 12.875 16.3495 12.875 16.25V11.935C12.875 11.8355 12.9145 11.7402 12.9848 11.6698C13.0552 11.5995 13.1505 11.56 13.25 11.56C13.3495 11.56 13.4448 11.5995 13.5152 11.6698C13.5855 11.7402 13.625 11.8355 13.625 11.935V16.25Z'
                              fill='#E1392E'
                            />
                            <path
                              d='M16.46 8.42504C15.8116 8.12299 15.1135 7.94169 14.4 7.89004C14.2692 7.3522 13.9614 6.87389 13.5262 6.53186C13.091 6.18984 12.5535 6.00391 12 6.00391C11.4465 6.00391 10.909 6.18984 10.4738 6.53186C10.0386 6.87389 9.73084 7.3522 9.6 7.89004C8.91039 7.94567 8.23466 8.1146 7.6 8.39004C7.43162 8.46303 7.28658 8.58094 7.18075 8.73086C7.07492 8.88079 7.01239 9.05694 7 9.24004C7 9.37265 7.05268 9.49982 7.14645 9.59359C7.24021 9.68736 7.36739 9.74004 7.5 9.74004H16.5C16.6326 9.74004 16.7598 9.68736 16.8536 9.59359C16.9473 9.49982 17 9.37265 17 9.24004C16.9945 9.06745 16.9411 8.8998 16.8457 8.75586C16.7504 8.61191 16.6168 8.49736 16.46 8.42504ZM12 7.00004C12.2687 6.99782 12.5327 7.07057 12.7624 7.21012C12.992 7.34968 13.1782 7.5505 13.3 7.79004C12.4341 7.73759 11.5659 7.73759 10.7 7.79004C10.819 7.54824 11.0045 7.34543 11.2348 7.20548C11.4651 7.06554 11.7306 6.99426 12 7.00004Z'
                              fill='#E1392E'
                            />
                          </svg>
                          <span className='text-neutral-900 text-sm font-medium'>
                            Remove
                          </span>
                        </button>
                      </div>
                      <div className='flex flex-col gap-[10px]'>
                        <div className='text-[1.125rem] font-bold font-wide'>
                          {formatCurrency(totalDapAmount)}
                        </div>
                      </div>
                    </div>
                    {vehicleConfig.isFleet && (
                      <div className='text-right text-[0.75rem] tracking-[0.0625rem] font-wide'>
                        Vehicle Types ({totalVehicleTypesSelected}) x $10ea
                      </div>
                    )}
                  </div>
                ) : (
                  <button
                    className='py-[10px] px-[28px] cursor-pointer bg-brand rounded-[100px] text-white text-xs font-extrabold font-wide uppercase tracking-wide hover:opacity-80 transition-opacity whitespace-nowrap'
                    onClick={() => toggleDap()}
                  >
                    + Add ({formatCurrency(totalDapAmount)})
                  </button>
                )}
              </div>

              {dapExpanded && (
                <div className='mb-0 p-6 rounded-lg bg-[#F5F5F5] border-[#F1F1F1] user-select-none'>
                  <div className='flex flex-col gap-4'>
                    <div>
                      <div className='chip'>Recommended</div>
                    </div>

                    <div>
                      <div className='flex justify-between gap-6  text-[1.125rem] font-bold font-wide'>
                        <div>
                          <div className='flex gap-3 items-center cursor-pointer pointer-events-none'>
                            Digital Asset Package - DAP
                          </div>
                        </div>

                        {dapChecked ? (
                          <div>Added</div>
                        ) : (
                          <div>{formatCurrency(totalDapAmount)}</div>
                        )}
                      </div>

                      {vehicleConfig.isFleet && (
                        <div className='text-right text-[0.75rem] tracking-[0.0625rem] pt-3 font-wide'>
                          Vehicle Types ({totalVehicleTypesSelected}) x $10ea
                        </div>
                      )}
                    </div>

                    <div className='text-[0.75rem] font-normal tracking-[0.03125rem] leading-[1.125rem] text-[#383A3A]'>
                      <div>
                        AIO PICTURES™ artificially intelligent movie studio:
                      </div>
                      <ul className='my-0'>
                        <li>Ultra High Definition Images of AIO CUSTOM™</li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <hr className='separator' />
          <div className='dueTodayContainer pt-6 pb-10'>
            <div className='flex justify-between'>
              <span className='dueToday text-[1.25rem] font-bold font-wide'>
                Total
              </span>
            </div>
            <div className='text-[1.25rem] font-bold font-wide'>
              <span>{formatCurrency(dueAmount)} USD</span>
            </div>
          </div>
        </div>

        <div className='reservationDetails'>
          <p className='form-paragraph'>
            This reservation saves a sequential place in line for purchasing
            AIO. Make AIO CUSTOM™ anytime.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Summary;
