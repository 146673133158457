import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Slide,
  Grow,
  styled,
  Menu,
  MenuItem,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '../ConfigurationComp/Utility/IconButtonComp';

function DoorOpenComp(props) {
  const containerRef = React.useRef(null);
  const open = props.open;
  const [doorOpen, setDoorOpen] = useState(true);
  const [openTrunk, setOpenTrunk] = useState(true);

  const anchorRef = useRef();

  const handleClose = (value) => {
    if (open) {
      props.setFeatureMode(null);
    } else {
      props.setFeatureMode('door');
    }
  };

  const doorOpenHandler = () => {
    props.threejsModule.openCloseDoor(doorOpen);
    setDoorOpen(!doorOpen);
  };

  const backTrunkDoor = () => {
    props.threejsModule.openCloseBackTrunk(openTrunk);
    setOpenTrunk(!openTrunk);
    // openCloseBackTrunk
  };

  const closeAllHandler = () => {
    props.threejsModule.openCloseBackTrunk(false);
    props.threejsModule.openCloseDoor(false);

    setDoorOpen(true);
    setOpenTrunk(true);
  };

  return (
    <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={400}>
      <Box ref={anchorRef} sx={{}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            gap: '10px',
          }}
        >
          {!open && (
            <IconButton
              active={true}
              onClick={handleClose}
              component={
                !doorOpen
                  ? './assets/images/icons/CarTopDoorswhite.svg'
                  : './assets/images/icons/CarTopDoorswhite.svg'
              }
            ></IconButton>
          )}

          {open && (

            <Box
              onClick={handleClose}
              sx={{
                cursor: "pointer",
                width: "48px",
                height: "48px",
                display: 'flex',
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                transition: "transform .3s",
              }}
            >
              <Box
                component='img'
                src="./assets/images/icons/Close.svg"
                sx={{
                  width: "20px",
                  height: "20px",
                  padding: "8px",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  animation: "rotateIn 0.2s linear"
                }}
              >
              </Box>

            </Box>
            // <IconButton
            //   onClick={handleClose}
            //   active={true}
            //   component='./assets/images/icons/Close.svg'
            // ></IconButton>


          )}
          <Grow in={open}>
            <Box>
              <IconButton
                onClick={closeAllHandler}
                active={true}
                component='./assets/images/icons/CarTopClose.svg'
              ></IconButton>
            </Box>
          </Grow>
          {props.carMode !== 'car' && (
            <Grow
              in={open}
              style={{ transformOrigin: '0 0 0' }}
              {...(open ? { timeout: 200 } : {})}
            >
              <Box>
                <IconButton
                  onClick={backTrunkDoor}
                  active={true}
                  component='./assets/images/icons/CarTopDoorBack.svg'
                ></IconButton>
              </Box>
            </Grow>
          )}

          <Grow
            in={open}
            style={{ transformOrigin: '0 0 0' }}
            {...(open ? { timeout: 400 } : {})}
          >
            <Box>
              <IconButton
                onClick={doorOpenHandler}
                active={true}
                component='./assets/images/icons/CarTopDoors.svg'
              ></IconButton>
            </Box>
          </Grow>
        </Box>
      </Box>
    </Grow>
  );
}

export default DoorOpenComp;
