import React, { useState } from 'react';
import { Box, Button, styled, Avatar, Typography, Collapse, Grow } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import { ga } from 'react-ga';

import CameraComps from "./CameraComps";
import DoorOpenComp from "./DoorOpenComp";
import SceneComp from "./SceneComp";

import IconButton from '../ConfigurationComp/Utility/IconButtonComp';

function SceneConfigureCompMobile(props) {

    const [open, setOpen] = useState(false)

    const closeHander = () => {
        props.setConfigurateOpen(!open)
        props.setFeatureMode(null)
        setOpen(!open)
    }

    const CustomButton = styled(Button)(({ theme }) => ({
        height: "40px",
        color: "#383A3A",
        fontFamily: 'Mona Sans',
        fontWeight: "800",
        borderRadius: "100px",
        backgroundColor: "white",
        padding: "0 20px",
        fontSize: "12px",
        // textTransform: "capitalize",
        '&::after': {
            content: '" "',
            position: "absolute",
            zIndex: -1,
            display: 'block',
            width: '100%',
            height: "100%",
            borderRadius: "100px",
            backgroundColor: "white",
            transitionProperty: "background-color, box-shadow,transform,filter",
            transitionDuration: "0.5s",
        },
        '&:hover': {
            backgroundColor: "white",
            '&::after': {
                transform: "scale(1.1)",
            },
        },

    }));

    return (
        <Box
            sx={{
                position: "absolute",
                right: `${props.expand ? "-300px" : "0"}`,
                top: '0',
                display: { xs: "flex", sm: "none" },
                margin: "20px",
                flexDirection: "column",
                alignItems: "flex-end   ",
                gap: "10px",
                width: "50px",
                transitionProperty: "right",
                transitionDuration: " 0.5s",
            }}
        >
            <CustomButton
                onClick={closeHander}
                sx={{
                    width: "100px",

                    // justifyContent: "flex-end",
                    // boxShadow: open ? "0px 0px 0px 4px white" : "none",
                }
                }
                endIcon={<ArrowDropDownIcon sx={{
                    transform: open ? "rotate(180deg)" : "rotate(0)",
                    transition: "transform 2s ease-out"
                }} />}
            >
                {open ? "CLOSE" : "Scene"}

            </CustomButton>
            {open && !props.isFleet &&
                <SceneComp threejsModule={props.threejsModule} open={props.featureMode === "scene"} setFeatureMode={props.setFeatureMode} />
            }
            {open &&
                <DoorOpenComp carMode={props.carMode} threejsModule={props.threejsModule} open={props.featureMode === "door"} setFeatureMode={props.setFeatureMode} />
            }
            {open &&
                <CameraComps isInterior={props.isInterior} threejsModule={props.threejsModule} open={props.featureMode === "camera"} setFeatureMode={props.setFeatureMode} />
            }
            {open && !props.isInterior &&
                <Grow
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    timeout={800}
                >
                    <Box>
                        <IconButton
                            active={true}
                            onClick={props.heightLightHandler}
                            component="./assets/images/icons/Headlight.svg"
                        >
                        </IconButton>
                    </Box>
                </Grow>
            }
            {open && props.isInterior &&
                <Grow
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    timeout={1000}
                >
                    <Box>
                        <IconButton
                            active={true}
                            onClick={props.powerOffHandler}
                            component="./assets/images/icons/PowerButton.svg"
                        >
                        </IconButton>
                    </Box>
                </Grow>
            }
            {open &&
                <Grow
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    timeout={1000}

                >
                    <Box>
                        <IconButton
                            active={true}
                            onClick={props.startAR}
                            component="./assets/images/icons/3D.svg"
                        >
                        </IconButton>
                    </Box>
                </Grow>


            }
        </Box>




    )

}

export default SceneConfigureCompMobile


