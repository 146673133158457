import React, { useState } from 'react';
import { Box, Button, styled, Avatar, Typography, Collapse, Grow } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import { ga } from 'react-ga';

import CameraComps from "./CameraComps";
import DoorOpenComp from "./DoorOpenComp";
import SceneComp from "./SceneComp";

import IconButton from '../ConfigurationComp/Utility/IconButtonComp';

function SceneConfigureComp(props) {

    const [open, setOpen] = useState(true)


    return (
        <Box
            sx={{
                position: "absolute",
                right: "0",
                top: '0',
                display: { xs: "none", sm: "flex" },
                margin: "20px",
                flexDirection: "column",
                alignItems: "flex-end   ",
                gap: "10px",
                width: "50px",
            }}
        >
            {open && !props.isFleet &&
                <SceneComp threejsModule={props.threejsModule} open={props.featureMode === "scene"} setFeatureMode={props.setFeatureMode} />
            }
            {open &&
                <DoorOpenComp carMode={props.carMode} threejsModule={props.threejsModule} open={props.featureMode === "door"} setFeatureMode={props.setFeatureMode} />
            }
            {open &&
                <CameraComps isInterior={props.isInterior} threejsModule={props.threejsModule} open={props.featureMode === "camera"} setFeatureMode={props.setFeatureMode} />
            }
            {open && !props.isInterior &&
                <Grow
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    timeout={800}
                >
                    <Box>
                        <IconButton
                            active={true}
                            onClick={props.heightLightHandler}
                            component="./assets/images/icons/Headlight.svg"
                        >
                        </IconButton>
                    </Box>
                </Grow>
            }
            {open && props.isInterior &&
                <Grow
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    timeout={1000}
                >
                    <Box>
                        <IconButton
                            active={true}
                            onClick={props.powerOffHandler}
                            component="./assets/images/icons/PowerButton.svg"
                        >
                        </IconButton>
                    </Box>
                </Grow>
            }
            {open &&
                <Grow
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    timeout={1000}

                >
                    <Box>
                        <IconButton
                            active={true}
                            onClick={props.startAR}
                            component="./assets/images/icons/3D.svg"
                        >
                        </IconButton>
                    </Box>
                </Grow>
            }
        </Box>


    )

}

export default SceneConfigureComp


