import { Box, Button } from "@mui/material";



function ExpandButton(props) {
    return (
        <Button
            onClick={props.onClick}
            sx={{
                display: { xs: "flex", sm: "none" },
                position: "absolute",
                bottom: `${props.expand ? "20px" : "120px"}`,
                left: "0",
                marginLeft: "20px",
                width: "48px",
                height: "48px",
                color: "#383A3A",
                fontDamily: "Rowdies",
                borderRadius: "100px",
                minWidth: "20px",
                border: props.border2 ? "3px solid #00000061" : "1px solid white",
                backgroundColor: props.active ? "white" : "#FFFFFF33",
                textTransform: "capitalize",
                transitionProperty: "background-color, box-shadow,transform,filter,bottom",
                transitionDuration: " 0.5s",
                '&:hover': {
                    backgroundColor: "white",
                    transform: "scale(1.1)",
                },
                '&:hover img': {
                    transitionProperty: "filter",
                    transitionDuration: " 0.5s",
                    filter: props.active ? "none" : "invert(1)"
                },
                '& img': {
                    transitionProperty: "background-color, box-shadow,transform,filter",
                    transitionDuration: " 0.5s",
                },
                "&::before":
                    props.border && {
                        content: '" "',
                        position: "absolute",
                        top: "-4px",
                        zIndex: "-1",
                        backgroundColor: " #ffffff75",
                        width: " 100%",
                        height: "100%",
                        borderRadius: "100px",
                        padding: "4px",

                    }

            }}
        >
            <Box
                sx={{
                    width: "20px",

                }}
                component="img"
                src={props.component}
            />
        </Button >
    )

}

export default ExpandButton