import React, { useRef, useState } from "react";
import { Box, Button, styled, Grow, Typography, Collapse, Grid, Divider, CardMedia } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

import SubMaterial from "./SubMaterial";



const ColorPickerFloor = (props) => {
    const [color, setColor] = useState(props.color.color)
    const [materialOpen, setMaterialOpen] = useState(false)


    const colorUpdateHandler = (e) => {
        // console.log(e.target.value)
        // setColor(e.target.value);

        const input = e.target.value


        if (e.target.value.length < 7) {
            setColor(e.target.value)

        }



        // console.log(e.target.value)



        if (input.length === 7) {
            if (input[0] === "#") {
                const data = {
                    material: props.type,
                    color: e.target.value
                }
                setColor(e.target.value)
                props.onChangeColor(data)
            }
        } else if (input.length === 6) {
            const data = {
                material: props.type,
                color: `#${e.target.value}`
            }
            props.onChangeColor(data)
        }
    }

    const setMaterialHandler = (value) => {

        setMaterialOpen(true)
        // console.log('=== SMH HANDLER ===')
        // console.log(value)

        // props.setMaterialMode(value)
    }

    const overAllClick = () => {
        if (props.mode !== props.type) {
            props.setMode(props.type)
        }
    }

    // console.log(props.color)
    // console.log(props.configureData)
    // configureData={props.configureData}


    return (


        <Box
            onClick={overAllClick}
            sx={{

                padding: props.mode !== props.type ? "12px 12px" : "20px 12px",
                boxShadow: props.mode === props.type ? "0px 0px 6px 3px #9f9e9e47" : "none",
                borderRadius: "3px",
                backgroundColor: props.mode === props.type ? "#383A3A" : "#F5F5F5",
                margin: "5px 0",
                borderRadius: props.mode === props.type ? "16px" : "52px",
                overflow: "hidden",
                position: "relative",
                transitionProperty: "filter",
                transitionDuration: " 0.5s",
                cursor: props.mode !== props.type ? 'pointer' : "default",
                '&:hover': {
                    filter: props.mode === props.type ? "none" : "brightness(90%)",
                },
            }}
        >


            <Box
                onClick={() => { props.setMode(props.type) }}
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    cursor: 'pointer',

                }}
            >
                <Typography
                    sx={{
                        marginRight: "auto",
                        color: props.mode === props.type ? "white" : "#383A3A",
                        fontWeight: "600",
                        fontSize: "12px",
                        textTransform: "capitalize",
                        fontFamily: 'Mona Sans',
                    }}

                >{props.title} </Typography>

                {props.mode !== props.type &&
                    <Typography
                        sx={{
                            color: props.mode === props.type ? "white" : "#9A9A9A",
                            textTransform: "uppercase",
                            fontSize: "0.7rem",
                            fontFamily: 'Mona Sans',
                            fontWeight: "600",
                        }}
                    > {props.color.material}</Typography>
                }
                {props.mode !== props.type &&

                    <Box
                        sx={{

                            // backgroundColor: props.color.color,
                            width: "28px",
                            height: '28px',
                            borderRadius: "100px",
                            // border: "2px solid white", 
                            // boxShadow: "0px 0px 3px 2px #35353526",
                        }}
                    ></Box>
                }
                {props.mode === props.type &&
                    <Typography
                        sx={{
                            color: "black",
                            // backgroundColor: "white",
                            fontSize: "0.7rem",
                            fontFamily: 'Mona Sans',
                            borderRadius: "50px",
                            padding: "3px 12px",
                            fontWeight: "600",
                            zIndex: "1",
                            cursor: "pointer",
                            position: "relative",
                            '&::after': {
                                content: '" "',
                                position: "absolute",
                                top: "0",
                                left: "0",
                                zIndex: "-1",
                                display: 'block',
                                cursor: "pointer",
                                width: '100%',
                                height: "100%",
                                borderRadius: "100px",
                                backgroundColor: "white",
                                transitionProperty: "background-color, box-shadow,transform,filter",
                                transitionDuration: "0.5s",
                            },
                            '&:hover': {
                                '&::after': {
                                    transform: "scale(1.1)",
                                    backgroundColor: "white",
                                },
                            },
                        }}
                    >DONE</Typography>
                }
            </Box>

            {
                props.mode === props.type &&
                <Box
                sx={{
                    position: "relative",
                    margin: "15px 10px 5px 0px",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        width: "500px",
                        backgroundColor: "#777777a3",
                        height: "1px",
                        left: "-100px",
                        // left: "-30",
                    }}
                ></Box>
            </Box>
            }




            <Collapse
                in={props.mode === props.type} timeout="auto" unmountOnExit>

                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        marginTop: "15px",
                    }}
                >
                    <Box
                        sx={{
                            color: "white",
                            marginRight: "auto",
                            fontFamily: 'Mona Sans',
                            fontSize: "0.8rem",
                            fontWeight: "600",
                        }}
                    >
                        Material
                    </Box>
                    <Box
                        sx={{
                            color: "white",
                            fontSize: "0.8rem",
                            textTransform: "uppercase",
                            fontFamily: 'Mona Sans',
                            fontWeight: "600",
                        }}
                    >
                        {props.color.material}
                    </Box>
                    <Box
                        onClick={() => { setMaterialHandler(props.type) }}
                        sx={{
                            width: "60px",
                            height: "24px",
                            backgroundColor: "black",
                            padding: "0",
                            cursor: 'pointer',
                            borderRadius: "4px",
                        }}
                    >

                    </Box>
                </Box>
            </Collapse>

            {materialOpen &&
                <SubMaterial setMaterialOpen={setMaterialOpen} configureData={props.configureData} threejsModule={props.threejsModule}  onClose={()=>{setMaterialOpen(false )}}/>
            }
        </Box >

    )

}


export default ColorPickerFloor

