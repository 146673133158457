import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Box, Button, alpha, Switch, styled, Collapse, Typography, Grow, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CloseComp from '../Utility/CloseComp';
import AeroComp from './Utility/AeroComp';
import RimComp from './Utility/RimComp';
import RimCompMobile from './Utility/RimCompMobile';
import AeroCompMobile from './Utility/AeroCompMobile';
import BackButton from '../Utility/BackButton';

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#000000",
        '&:hover': {
            backgroundColor: alpha("#000000", theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#000000",
    },
}));

function WheelCompMobile(props) {

    const [mode, setMode] = useState(null)
    const coverShow = props.configureData.aeroCover
    const coverColor = props.configureData.colors.cover.color
    const rimColor = props.configureData.colors.rim.color

    // const [coverShow, setCoverShow] = useState(props.configureData.aeroCover)


    const coverHandleChange = (event) => {
        props.threejsModule.showHideCover(event.target.checked)

    };



    const setModeHandler = (value) => {
        if (value === mode) {
            setMode(null)
        } else {
            props.threejsModule.activeMaterial(value)
            setMode(value)
        }
    }
    const handleClose = () => {
        props.setConfigureMode(null)
    }


    const backHandler = () => {

        if (mode) {
            setMode(null)
        } else {
            props.setConfigureMode(null)
            props.setPaddingB(window.innerHeight - 270)
        }

    }



    const titleConvert = (mode) => {

        let title = "Wheel"

        switch (mode) {
            case "cover":
                title = "AEROSHIELD™"
                break;
            case "rim":
                title = "Rims"
                break;
            default:
                title = "Wheel"
                break;
        }

        return title

    }


    const colorUpdateHandler = (data) => {

        props.threejsModule.updateColor(data)
        // props.onChangeColor(e.target.value)
        // updateWheel
    }

    const romColorUpdateHandler = (data) => {
        props.threejsModule.updateColor(data)
        // props.onChangeColor(e.target.value)
    }


    return (
        <Grow
            in={true}
            style={{ transformOrigin: '1 1 1' }}
        >
            <Box
                sx={{
                    position: "absolute",
                    bottom: "0",
                    width: { xs: "calc(100% - 40px)", sm: "260px" },
                    margin: { xs: "0", sm: "20px" },
                    padding: '20px',
                    backgroundColor: "white",
                    borderRadius: "32px",
                    display: { xs: "flex", sm: "none" },
                    gap: '5px',
                    flexDirection: "column",
                    zIndex: "1000"

                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: "space-between",
                        padding: "0 10px",
                        marginBottom: "10px"
                    }}
                >
                    <BackButton onClick={backHandler} />
                    <Typography
                        sx={{
                            fontWeight: "700",
                            fontFamily: 'Mona Sans',
                            fontSize: "24px",
                            alignSelf: "center",
                            marginRight: "auto",
                            marginLeft: "10px"
                        }}
                    >
                        {titleConvert(mode)}
                    </Typography>

                    <CloseComp
                        onClick={handleClose}
                    ></CloseComp>
                </Box>

                {!mode &&
                    <AeroComp color={coverColor} mode={mode} setMode={setModeHandler} type="cover" title="AEROSHIELD™" colorUpdateHandler={colorUpdateHandler} coverHandleChange={coverHandleChange} coverShow={coverShow} />
                }

                {!mode &&
                    <RimComp color={rimColor} mode={mode} setMode={setModeHandler} type="rim" title="Rims" colorUpdateHandler={romColorUpdateHandler} />

                }
                {mode === "cover" &&
                    <AeroCompMobile color={coverColor} mode={mode} setMode={setModeHandler} type="cover" title="AEROSHIELD™" colorUpdateHandler={colorUpdateHandler} coverHandleChange={coverHandleChange} coverShow={coverShow} />
                }
                {mode === "rim" &&
                    <RimCompMobile color={rimColor} mode={mode} setMode={setModeHandler} type="rim" title="Rims" colorUpdateHandler={romColorUpdateHandler} />
                }

            </Box >
        </Grow>
    )

}

export default WheelCompMobile