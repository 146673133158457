

const downloadData = async () => {
    try {
        let data = await fetch(`./assets/appData.json`)
        if (data) {
            const jsonData = data.json()
            return jsonData
        }
    }
    catch (error) {
        // TypeError: Failed to fetch
        console.log('There was an error', error);
        return error
    }
}


function uploadData(x) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({uuid: "123456"});
        }, 2000);

    });
}



export { downloadData, uploadData }