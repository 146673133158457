import App from './App';
import { useEffect, useState } from 'react';

import { downloadData } from './components/Modules/DataInputUpload';
import apiURL from './util/apiURL.js';

function LoadData() {
  const [configureData, setConfigureData] = useState();
  const [reservationData, setReservationData] = useState();

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('fleet');
  const vehicleParam = urlParams.get('vehicle');
  const reservationParam = urlParams.get('reservation');

  async function loadReservationData(reservationParam) {
    const response = await fetch(
      `${apiURL}/api/reservations/${reservationParam}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) {
      throw Error(body.message);
    }
    return body;
  }

  const loadData = async () => {
    if (reservationParam) {
      const reservationData = await loadReservationData(reservationParam);

      reservationData.body.vehicle.logoUrl = reservationData.body.logo_url;
      setConfigureData(reservationData.body.vehicle);

      setReservationData({
        firstName: reservationData.body.first_name,
        lastName: reservationData.body.last_name,
        companyName: reservationData.body.company_name,
        emailAddress: reservationData.body.email_address,
        dapPurchased:
          reservationData.body.dap === null ? false : reservationData.body.dap,
        createdAt: reservationData.body.created_at,
        updatedAt: reservationData.body.updated_at,
        cardBrand: reservationData.body.card_brand,
        lastFour: reservationData.body.card_last_four,
        cardFunding: reservationData.body.card_funding,

        customerId: reservationData.body.stripe_customer_id,
        uuid: reservationData.body.aio_verse_url,
      });
    } else {
      const data = await downloadData();

      if (data) {
        const isFleet = data.fleet || myParam;

        data.carType = vehicleParam || data.carType;

        if (isFleet) {
          data.carType = 'van';
          data.isFleet = true;
        }

        setConfigureData(data);
        // console.log(data);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    configureData && (
      <App
        configureData={configureData}
        setConfigureData={setConfigureData}
        reservationData={reservationData}
        setReservationData={setReservationData}
      />
    )
  );
}

export default LoadData;
