import React, { useState } from 'react';
import { Box, Button, CircularProgress, Avatar, Typography, Collapse } from "@mui/material";
import { keyframes } from '@mui/system';




function PortraitComp(props) {


    return (
        <Box
            className="webxr-portrait"
            sx={{
                cursor: "grab",
                width: "100vw",
                height: "100vh",
                backgroundColor: "#212121",
                position: "absolute",
                top: "0",
                zIndex: "500",
                flexDirection: "column",
                gap: "20px",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "16px",
                    flexDirection: 'column',
                    gap: "20px",
                }}
            >

                <Box
                    component="img"
                    src="./assets/images/icons/aio.svg"
                    sx={{
                        width: "100px"
                    }}
                >
                </Box>
                <Typography
                    sx={{
                        fontFamily: 'Mona Sans',
                        fontWeight: "800",
                        fontSize: "20px",
                        lineHeight: "20px",
                        letterSpacing: "1px",
                        color: "white",
                        textAlign: "center",
                        maxWidth:"450px",
                        margin:"20px",
                    }}
                >
                    Rotate your phone to portrait view for the best experience
                </Typography>
            </Box>

        </Box>
    )

}

export default PortraitComp

