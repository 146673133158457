import React, { useRef, useState } from "react";
import { Box, Button, styled, Grid, Grow, Typography, Collapse, CardMedia, Divider, TextField } from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SubColorPickerSelect from "./SubColorPickerSelect";

const SubColorPicker = (props) => {
    const [color, setColor] = useState(props.color.color)

    const [openPicker, setOpenPicker] = useState(false)
    const inputRef = useRef()
    const onSelectHandler = (e) => {
        setOpenPicker(false)
        colorUpdateHandler(e.color)
    }

    const colorUpdateHandler = (e) => {
        // console.log(e.target.value)
        // setColor(e.target.value);


        const input = e.target?.value || e

        if (input.length < 7) {
            setColor(input)
        }

        // console.log(e.target.value)

        if (input.length === 7) {
            if (input[0] === "#") {
                const data = {
                    material: props.type,
                    color: input
                }
                setColor(input)
                props.onChangeColor(data)
            }
        } else if (input.length === 6) {
            const data = {
                material: props.type,
                color: `#${input}`
            }
            props.onChangeColor(data)
        }
    }

    const setMaterialHandler = (value) => {
        console.log('=== SMH HANDLER ===')
        console.log(value)

        props.setMaterialMode(value)
    }

    const overAllClick = () => {
        if (props.mode !== props.type) {
            props.setMode(props.type)
        }
    }

    // console.log(props.color)
    // console.log(props.configureData)
    // configureData={props.configureData}


    return (


        <Box
            onClick={overAllClick}
            sx={{
                padding: props.mode !== props.type ? "12px 12px" : "20px 12px",
                boxShadow: props.mode === props.type ? "0px 0px 6px 3px #9f9e9e47" : "none",
                borderRadius: "3px",
                backgroundColor: props.mode === props.type ? "#383A3A" : "#F5F5F5",
                margin: "5px 0",
                borderRadius: props.mode === props.type ? "16px" : "52px",
                overflow: "hidden",
                position: "relative",
                transitionProperty: "filter",
                transitionDuration: " 0.5s",
                cursor: props.mode !== props.type ? 'pointer' : "default",
                '&:hover': {
                    filter: props.mode === props.type ? "none" : "brightness(90%)",
                },
            }}
        >


            <Box
                onClick={() => { props.setMode(props.type) }}
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    cursor: 'pointer',

                }}
            >
                <Typography
                    sx={{
                        marginRight: "auto",
                        color: props.mode === props.type ? "white" : "#383A3A",
                        fontWeight: "600",
                        fontSize: "12px",
                        textTransform: "capitalize",
                        fontFamily: 'Mona Sans',
                    }}

                >{props.title} </Typography>

                {/* {props.mode !== props.type &&
                    <Typography
                        sx={{
                            color: props.mode === props.type ? "white" : "#9A9A9A",
                            textTransform: "uppercase",
                            fontSize: "0.7rem",
                            fontFamily: 'Mona Sans',
                            fontWeight: "600",
                        }}
                    > {props.color.material}</Typography>
                } */}
                {props.mode !== props.type &&

                    <Box
                        sx={{
                            backgroundColor: props.color.color,
                            width: "28px",
                            height: '28px',
                            borderRadius: "100px",
                            // border: "2px solid white", 
                            // boxShadow: "0px 0px 3px 2px #35353526",
                        }}

                    ></Box>
                }
                {props.mode === props.type &&
                    <Typography
                        sx={{
                            color: "black",
                            // backgroundColor: "white",
                            fontSize: "0.7rem",
                            fontFamily: 'Mona Sans',
                            borderRadius: "50px",
                            padding: "3px 12px",
                            fontWeight: "600",
                            zIndex: "1",
                            cursor: "pointer",
                            position: "relative",
                            '&::after': {
                                content: '" "',
                                position: "absolute",
                                top: "0",
                                left: "0",
                                zIndex: "-1",
                                display: 'block',
                                cursor: "pointer",
                                width: '100%',
                                height: "100%",
                                borderRadius: "100px",
                                backgroundColor: "white",
                                transitionProperty: "background-color, box-shadow,transform,filter",
                                transitionDuration: "0.5s",
                            },
                            '&:hover': {
                                '&::after': {
                                    transform: "scale(1.1)",
                                    backgroundColor: "white",
                                },
                            },
                        }}
                    >DONE</Typography>
                }
            </Box>

            {
                props.mode === props.type &&
                <Box
                sx={{
                    position: "relative",
                    margin: "15px 10px 5px 0px",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        width: "500px",
                        backgroundColor: "#777777a3",
                        height: "1px",
                        left: "-100px",
                        // left: "-30",
                    }}
                ></Box>
            </Box>

            }



            <Collapse
                in={props.mode === props.type} timeout="auto" unmountOnExit>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        marginTop: "15px",
                    }}
                >
                    <Box
                        sx={{
                            color: "white",
                            marginRight: "auto",
                            fontFamily: 'Mona Sans',
                            fontSize: "0.8rem",
                            fontWeight: "600",
                        }}
                    >
                        Color
                    </Box>

                    <Box
                        sx={{
                            color: "#7F7F7F",
                            fontSize: "0.7rem",
                            display: "none",
                            alignItems: "center",
                            border: "2px solid #777777",

                        }}
                    >
                        <Typography
                            sx={{
                                color: "white",
                                padding: "1px 6px",
                                fontWeight: "600",
                                fontSize: "0.8rem",
                                fontFamily: 'Mona Sans',
                                backgroundColor: "#777777",

                            }}
                        >
                            HEX
                        </Typography>
                        <Box
                            onInput={colorUpdateHandler}
                            component="input"
                            value={color[0] === "#" ? color.substring(1) : color}
                            sx={{
                                width: "70px",
                                color: "white",
                                fontWeight: "500",
                                textAlign: 'center',
                                borderRadius: "0",
                                fontWeight: "600",
                                border: "0px",
                                height: "19px",
                                boxShadow: "none",
                                cursor: 'pointer',
                                backgroundColor: "#ffffff00",
                                textTransform: "uppercase",
                                '&:focus': {
                                    border: "0px",

                                }
                            }}
                        ></Box>
                    </Box>
                    <Box
                        onClick={() => { setOpenPicker(true) }}
                        sx={{
                            border: "0px solid #777777",
                            padding: "0",
                            backgroundColor: props.color.color,
                            width: "60px",
                            height: "24px",
                            borderRadius: "4px",
                            cursor: 'pointer',
                        }}
                    >
                        <input
                            ref={inputRef}
                            style={{
                                opacity: "0",
                                display: "none",
                                cursor: 'pointer',
                            }} onChange={colorUpdateHandler} type="color" value={color} />

                    </Box>
                </Box>
            </Collapse>

            {openPicker &&
                <SubColorPickerSelect color={color} colors={props.colors} onSelect={onSelectHandler} onClose={() => { setOpenPicker(false) }} />
            }

        </Box >
    )
}



export default SubColorPicker


