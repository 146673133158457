import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Box, Grow, styled, Avatar, Typography, Collapse } from "@mui/material";
import CloseComp from '../../Utility/CloseComp';
import BackButton from '../../Utility/BackButton';

const SubColorCompMobile = (props) => {

    console.log(props.configureData)
    const [color, setColor] = useState(props.configureData.colors[props.mode].color)

    const matt = props.configureData.colors[props.mode].material

    console.log({ props })

    const colorDataSet = {
        body: "Main Body",
        front: "Front Bumper",
        rear: "Rear Bumper",
        roof: "Roof",
        rocket: "Rocker",
        trim: "Window Trim",
        grille: "Grille",
        cover: "AEROSHIELD™",
        rim: "Rim",
        ceiling: "Ceiling",
        screen: "Horizon Screen",
        seat: "Seat",
        wall: "Wall Panel Lower",
        floor: "Floor",
        hScreen: "Horizon Screen",
        accent: "Accent",
    }


    const colorUpdateHandler = (e) => {
        // console.log(e.target.value)
        // setColor(e.target.value);

        console.log(props.configureData)

        // const color = props.configureData[]

        // setColor(e.target.value)

        // console.log(e.target.value)



        const input = e.target.value


        if (e.target.value.length < 7) {
            setColor(e.target.value)

        }

        if (input.length === 7) {

            if (input[0] === "#") {
                const data = {
                    material: props.type,
                    color: e.target.value
                }
                setColor(e.target.value)
                props.onChangeColor(data)
            }

        } else if (input.length === 6) {
            const data = {
                material: props.mode,
                color: `#${e.target.value}`
            }
            props.onChangeColor(data)
        }
    }

    const setMaterialHandler = (value) => {

        console.log(value)

        props.setMaterialMode(value)
    }

    const backHandler = () => {
        props.setMode(null)
    }



    const handleClose = () => {
        props.setConfigureMode(null)
        props.setMode(null)
    }


    return (

        <Grow
            in={true}
            style={{ transformOrigin: '1 1 1' }}
        >
            <Box
                sx={{
                    position: "absolute",
                    bottom: "0",
                    width: "calc(100% - 40px)",
                    margin: "0px",
                    padding: '20px',
                    backgroundColor: "white",
                    borderRadius: "32px",
                    display: 'flex',
                    gap: '5px',
                    flexDirection: "column",
                    zIndex: "1000",
                    left: "0",
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: "space-between",
                        padding: "0 10px",
                        marginBottom: "10px"
                    }}
                >

                    <BackButton onClick={backHandler} />
                    <Typography
                        sx={{
                            fontWeight: "700",
                            fontFamily: 'Mona Sans',
                            fontSize: "24px",
                            alignSelf: "center",
                            marginRight: "auto",
                            marginLeft: "10px"
                        }}
                    >{colorDataSet[props.mode]}</Typography>

                    <CloseComp

                        onClick={handleClose}
                    ></CloseComp>
                </Box>

                <Box
                    sx={{
                        backgroundColor: "#383A3A",
                        borderRadius: "16px",
                        padding: "12px 12px",

                    }}
                >

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                            // marginTop: "15px",
                            margin: "5px 0"

                        }}
                    >
                        <Box
                            sx={{
                                color: "white",
                                marginRight: "auto",
                                fontFamily: 'Mona Sans',
                                fontSize: "0.8rem",
                                fontWeight: "600",
                            }}
                        >
                            Color
                        </Box>

                        <Box
                            sx={{
                                color: "#7F7F7F",
                                fontSize: "0.7rem",
                                display: "flex",
                                alignItems: "center",
                                border: "2px solid #777777",

                            }}
                        >
                            <Typography
                                sx={{
                                    color: "white",
                                    padding: "1px 6px",
                                    fontWeight: "600",
                                    fontSize: "0.8rem",
                                    fontFamily: 'Mona Sans',
                                    backgroundColor: "#777777",

                                }}
                            >
                                HEX
                            </Typography>
                            <Box
                                onInput={colorUpdateHandler}
                                component="input"
                                value={color[0] === "#" ? color.substring(1) : color}
                                sx={{
                                    width: "70px",
                                    color: "white",
                                    fontWeight: "500",
                                    textAlign: 'center',
                                    borderRadius: "0",
                                    fontWeight: "600",
                                    border: "0px",
                                    height: "19px",
                                    boxShadow: "none",
                                    cursor: 'pointer',
                                    backgroundColor: "#ffffff00",
                                    textTransform: "uppercase",
                                    '&:focus': {
                                        border: "0px",

                                    }
                                }}
                            ></Box>
                        </Box>
                        <Box
                            sx={{
                                border: "0px solid #777777",
                                padding: "0",
                                backgroundColor: color.length === 7 ? color : `#${color}`,
                                width: "60px",
                                height: "24px",
                                borderRadius: "4px",
                                cursor: 'pointer',
                            }}
                        >
                            <input style={{
                                opacity: "0",
                                cursor: 'pointer',
                                width: "100%",
                            }} onChange={colorUpdateHandler} type="color" value={props.color} />

                        </Box>
                    </Box>

                </Box>
            </Box >
        </Grow>

    )

}

export default SubColorCompMobile

