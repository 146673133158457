import * as THREE from "three";
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment.js";


class WebXRModule {
    constructor(data) {
        // this.scene = data.scene
        this.scene = new THREE.Scene();
        // ar 
        this.arScene = data.scene
        // this.renderer = data.renderer

        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
        });

        this.scene.add(this.arScene)

        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;

        // this.renderer.useLegacyLights = false;
        // this.renderer.shadowMap.enabled = true;
        // this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.toneMappingExposure = 1;
        this.renderer.xr.enabled = true;


        const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        pmremGenerator.compileEquirectangularShader();
        this.scene.environment = pmremGenerator.fromScene(
            new RoomEnvironment(),
            0.04
        ).texture;



        this.camera = data.camera
        this.controlPlace = false;
        this.initPlaced = true;
        this.session = null
        this.localReferenceSpace = null;
        this.viewerReferenceSpace = null;
        this.hitTestSourceRequested = false;
        this.hitTestSource = null;
        this.transientHitTestSource = null;
        this.raycaster = new THREE.Raycaster();

        this.mouse = new THREE.Vector2();
        this.xrAnimate = null

        this.sceneEnd = false
        this.rayCasterProxy = new THREE.Mesh(new THREE.BoxGeometry(0.2, 0.2, 0.4));
        this.rayCasterProxy.visible = false;
        // this.rayCasterProxy.scale.set(0.09, 0.098, 0.098)
        this.rayCasterProxy.position.set(0, 0.1, 0)

        this.arScene.add(this.rayCasterProxy);

        this.toucheRayCasterProxy = false
        this.initialScale = 1
        this.initSeparation = 1
        this.cameraPosition = new THREE.Vector3()

        this.closeBtnSvg = `<svg  style="width:100%, heigth="100%" width="50" height="50" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d='M22.3513 69.9811C20.5847 71.7477 20.5006 74.9024 22.3933 76.711C24.202 78.6038 27.3987 78.5197 29.1653 76.7531L49.5654 56.3531L69.9654 76.7531C71.7741 78.5618 74.8866 78.6038 76.6953 76.711C78.5881 74.9024 78.546 71.7477 76.7374 69.9391L56.3373 49.539L76.7374 29.181C78.546 27.3303 78.5881 24.2177 76.6953 22.4091C74.8866 20.5163 71.7741 20.5583 69.9654 22.367L49.5654 42.7671L29.1653 22.367C27.3987 20.6004 24.202 20.5163 22.3933 22.4091C20.5006 24.2177 20.5847 27.3724 22.3513 29.139L42.7513 49.539L22.3513 69.9811Z' fill="white"/>
        </svg>`


        this.domOverlay = document.createElement('div');
        this.domOverlay.style.position = "absolute"
        this.domOverlay.style.top = "0"
        // this.domOverlay.style.height = "100%"
        // this.domOverlay.style.width = "100%"
        this.domOverlay.id = "ar-overlay"

        this.domOverlay.style.display = "none"

        this.closeImg = document.createElement('div')
        this.closeImg.style.position = "absolute"
        this.closeImg.style.right = "0"
        this.closeImg.style.top = "0"
        this.closeImg.style.margin = "20px"
        this.closeImg.style.height = "50px"
        this.closeImg.style.width = "50px"
        this.closeImg.style.backgroundColor = "#ffffff47"
        this.closeImg.style.border = "2px solid white"
        this.closeImg.style.borderRadius = "100px"

        this.closeImg.id = 'closeImg'
        this.closeImg.innerHTML = this.closeBtnSvg

        this.domOverlay.appendChild(this.closeImg)

        document.body.append(this.domOverlay)

        // functions
        this.onEnd = () => { }


    }


    async startAR() {

        this.scene.add(this.arScene)
        this.initPlaced = true;
        this.controlPlace = false
        this.sceneEnd = false

        this.cameraPosition.copy(this.camera.position)
        this.domOverlay.style.display = "block"


        this.session = await navigator.xr.requestSession("immersive-ar", {
            requiredFeatures: ["hit-test"],
            optionalFeatures: ["dom-overlay"],
            domOverlay: { root: document.querySelector("#ar-overlay") },
        });

        this.localReferenceSpace = await this.session.requestReferenceSpace("local");
        this.viewerReferenceSpace = await this.session.requestReferenceSpace("viewer");


        this.session
            .requestHitTestSourceForTransientInput({ profile: "generic-touchscreen" })
            .then((hitTestSource) => {
                this.transientHitTestSource = hitTestSource;
            });

        this.session.addEventListener("end", this.sessionEnd.bind(this));
        this.session.addEventListener("selectstart", this.onSelectStart.bind(this));
        this.session.addEventListener("selectend", this.onSelectEnd.bind(this));

        this.renderer.xr.setReferenceSpaceType("local");
        await this.renderer.xr.setSession(this.session);
        this.session.requestAnimationFrame(this.onXRFrame.bind(this));

        // console.log("start frame")

        document.addEventListener(
            "keydown",
            (event) => {
                const keyName = event.key;

                if (keyName === "Escape") {
                    this.exitAR()
                }
            },
            false,
        );

        this.closeImg.addEventListener(
            "click",
            (event) => {
                this.exitAR()
            },
            false,
        );
    }


    exitAR() {
        this.sceneEnd = true
        cancelAnimationFrame(this.xrAnimate);
        this.onEnd()

        this.session?.end()
        console.log("exit webXR")
    }

    sessionEnd() {
        this.sceneEnd = true
        this.session.removeEventListener("end", this.sessionEnd);
        this.session.removeEventListener("selectstart", this.onSelectStart);
        this.session.removeEventListener("selectend", this.onSelectEnd);
        this.session = null;
        // cancelAnimationFrame(this.xrAnimate);
        this.arScene.position.set(0, 0, 0)
        this.arScene.rotation.set(0, 0, 0)
        this.arScene.scale.set(1, 1, 1)
        this.camera.position.copy(this.cameraPosition)
        this.domOverlay.style.display = "none"
    }

    onSelectStart() {
        console.log(this.arScene.scale)
        this.hitTestSourceRequested = false;
        this.initialScale = this.arScene.scale.x;
    }

    onSelectEnd() {
        this.hitTestSourceRequested = true;
        setTimeout(() => {
            this.controlPlace = false
        }, 50)
        this.initSeparation = 1
        // console.log("select end")
    }

    onXRFrame(time, frame) {

        if (!this.sceneEnd) {

            this.session = frame.session;
            this.xrAnimate = this.session.requestAnimationFrame(this.onXRFrame.bind(this));
            this.renderer.render(this.scene, this.camera);
            this.processInput(frame);

            // console.log(this.controlPlace)
            // console.log(this.initPlaced)

            if (this.initPlaced || this.controlPlace) {
                const offsetDirection = this.controlPlace
                    ? new THREE.Vector4(this.mouse.x * 0.35, this.mouse.y * 0.65, -1, 0).normalize()
                    : new THREE.Vector4(0, 0, -1, 0);

                const XrayOffset = new window.XRRay(new DOMPoint(0, 0, 0), offsetDirection);

                this.session
                    .requestHitTestSource({
                        space: this.viewerReferenceSpace,
                        offsetRay: XrayOffset,
                    })
                    .then((source) => {
                        if (source) {
                            this.hitTestSource = source;
                        } else {
                            this.hitTestSource = null;
                        }
                    })


                if (this.hitTestSource) {
                    const hitTestResults = frame.getHitTestResults(this.hitTestSource);
                    if (hitTestResults.length) {
                        const hit = hitTestResults[0];
                        const positions = hit.getPose(this.localReferenceSpace).transform.position;
                        this.arScene.position.copy(positions);
                        this.initPlaced = false
                        // console.log(hit.getPose(this.localReferenceSpace))
                    }
                }
            }
        }
    }
    processInput(frame) {

        // console.log(this.transientHitTestSource)
        if (!this.transientHitTestSource) {
            return;
        }
        const fingers = frame.getHitTestResultsForTransientInput(
            this.transientHitTestSource
        );
        // console.log({ fingers })
        if (fingers.length === 2) {

            const { separation, deltaYaw } = this.fingerPolar(fingers);
            if (Math.abs(deltaYaw) < 0.5) {
                this.arScene.rotateY(deltaYaw);
            }

            if (this.initSeparation === 1) {
                this.initSeparation = separation
            } else {
                const deltSeparation = separation / this.initSeparation
                // this.initSeparation
                const newScale = this.initialScale * deltSeparation
                this.arScene.scale.set(newScale, newScale, newScale)
                console.log(this.initialScale, this.initSeparation, this.arScene.scale.x)
                console.log({ newScale, separation, deltSeparation })
            }


        } else if (fingers.length === 1) {
            this.xrayPosition(fingers);

        }
    }
    xrayPosition(fingers) {
        this.mouse.set(
            fingers[0].inputSource.gamepad.axes[0],
            -fingers[0].inputSource.gamepad.axes[1]
        );
        this.raycaster.setFromCamera(this.mouse, this.camera);
        const intersects = this.raycaster.intersectObject(this.rayCasterProxy, true);
        if (intersects.length > 0 && !this.controlPlace) {
            // console.log(this.mouse)
            // console.log({ intersects })
            this.controlPlace = true
            // placeMode = true;
            // hitTestSourceRequested = false;
        }
    }

    fingerPolar(fingers) {
        const fingerOne = fingers[0].inputSource.gamepad.axes;
        const fingerTwo = fingers[1].inputSource.gamepad.axes;
        const deltaX = fingerTwo[0] - fingerOne[0];
        const deltaY = fingerTwo[1] - fingerOne[1];
        const angle = Math.atan2(deltaY, deltaX);
        let deltaYaw = this.lastAngle - angle;
        if (deltaYaw > Math.PI) {
            deltaYaw -= 2 * Math.PI;
        } else if (deltaYaw < -Math.PI) {
            deltaYaw += 2 * Math.PI;
        }
        this.lastAngle = angle;
        return {
            separation: Math.sqrt(deltaX * deltaX + deltaY * deltaY),
            deltaYaw: deltaYaw,
        };
    }
}


export default WebXRModule;
