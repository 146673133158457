import React, { useRef, useState } from "react";
import { Box, Button, styled, Grid, Grow, Typography, Collapse, CardMedia, Divider, TextField } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import BackButton from '../../Utility/BackButton';
import CloseComp from '../../Utility/CloseComp';


const SubColorPickerSelectMobile = (props) => {


    console.log(props.color)

    const colors = [
        {
            title: "Black",
            id: 0,
            preview: "./assets/materials/gloss.png",
            hex: "#2D2D2D"
        },
        {
            title: "White",
            id: 1,
            preview: "./assets/materials/matt.png",
            hex: "#ffffff"
        },
        {
            title: "Tan",
            id: 2,
            preview: "./assets/materials/carpet.png",
            hex: "#D2B48C"
        },
    ]



    const clossHandler = () => {

        props.onClose()
    }

    const backHandler = () => {
        // props.setMode(null)
        props.onBack()
    }

    return (
        <Grow
            in={true}
            style={{ transformOrigin: '1 1 1' }}
        >
            <Box
                sx={{
                    position: "fixed",
                    bottom: { xs: "0", sm: "60px" },
                    margin: { xs: "0", sm: "10px 20px" },
                    backgroundColor: "white",
                    borderRadius: "5px",
                    display: 'flex',
                    flexDirection: "column",
                    width: { xs: "100%", sm: "420px" },
                    boxShadow: "0px 0px 6px 3px #9f9e9e47",
                    overflow: "hidden",
                    zIndex: "500",
                    left: "0"
                }}
            >

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: "space-between",
                        padding: "20px 10px",
                        boxShadow: "0px 0px 6px 3px #9f9e9e47",
                    }}
                >
                    <BackButton onClick={backHandler} />
                    <Typography
                        sx={{
                            fontWeight: "700",
                            fontFamily: 'Mona Sans',
                            fontSize: "24px",
                            alignSelf: "center",
                            marginRight: "auto",
                            marginLeft: "10px"
                        }}
                    >{props.panelMode}</Typography>

                    <CloseComp

                        onClick={props.onClose}
                    ></CloseComp>
                </Box>

                <Grid container spacing={1}

                    sx={{
                        // width: "100%",
                        padding: "30px",
                        width: "calc(100% + 14px)",
                        // height: "300px",
                        overflowY: "scroll",
                        justifyContent: "space-evenly",
                        margin: "0"
                    }}
                >
                    {
                        colors.map((color, index) => (
                            <Grid
                                sx={{
                                    padding: "5px",

                                }}
                                key={index}
                            >
                                <Box
                                    onClick={() => {
                                        props.onSelect({ color: color.hex })
                                        // props.threejsModule.updateMaterial({ mode: props.mode, material })
                                    }}
                                    sx={{
                                        width: "90px",
                                        height: "90px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        cursor: "pointer",
                                        boxShadow: props.color === color.hex ? "0px 0px 6px 3px #9f9e9e47" : "none",
                                        '&:hover': {
                                            boxShadow: "0px 0px 6px 3px #9f9e9e47",
                                        }
                                    }}
                                >
                                    <CardMedia
                                        sx={{
                                            height: 60,
                                            width: 60,
                                        }}
                                        image={color.preview}
                                        title="green iguana"
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: "0.6rem",
                                            color: "#B8B8B8",
                                            fontFamily: 'Mona Sans',
                                        }}
                                        gutterBottom variant="h5" component="div">
                                        {color.title}
                                    </Typography>
                                </Box>

                            </Grid>
                        ))
                    }

                </Grid>
            </Box >
        </Grow>

    )

}

export default SubColorPickerSelectMobile
