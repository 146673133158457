import React, { useRef, useState, useContext } from 'react';
import { Box, Button, Slider, Grow, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Height } from '@mui/icons-material';
import CloseComp from '../Utility/CloseComp';
import AIOContext from '../../../AIOContext.js';

function UploadLogoComp(props) {
    const { logoData, setLogoData } = useContext(AIOContext).logo;
    const [logoScale, setLogoScale] = useState(50);
    const [logoName, setLogoName] = useState(props.configureData.logoUrl);
    const logoInputRef = useRef()


    const handleChange = (event, newValue) => {

    };

    const handleClose = () => {
        // props.threejsModule.updateCar(props.name)
        props.setConfigureMode(null)
    }



    const fileInputChangeHandler = (e) => {
        // console.log(e)
        setLogoName(e.target.files[0].name)
        // add the chosen logo as base64 data to vehicleconfig
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
          setLogoData(reader.result);
        };
        reader.onerror = (error) => {
          console.log('Error: ', error);
        };
    }

    const removeLogoHandler = () => {
        setLogoName(null)
        props.threejsModule.removeLogo()
        setLogoData("");
        logoInputRef.current.value = null
    }

    const fileName = (url) => {
        if (url) {
            const splitUrl = url.split('/')
            return splitUrl[splitUrl.length - 1]
        } else {
            return "No logo file..."
        }
    }

    const scaleInputStartHandler = () => {
        props.threejsModule.scaleInputStart()
    }
    const scaleInputendHandler = () => {
        props.threejsModule.scaleInputEnd()
        setLogoScale(50);
    }
    const onScaleInputHandler = (event, newValue) => {
        setLogoScale(newValue);
        props.threejsModule.onScaleInput(newValue)
    }



    return (
        <Grow
            in={props.configureMode === "logo"}
            style={{ transformOrigin: '1 1 1' }}
        >
            <Box
                sx={{
                    position: "absolute",
                    bottom: "0",
                    margin: { xs: "0", sm: "20px" },
                    padding: '30px',
                    backgroundColor: "white",
                    borderRadius: "32px",
                    display: props.configureMode === "logo" ? 'flex' : "none",
                    gap: '10px',
                    flexDirection: "column",
                    fontFamily: 'Mona Sans',
                    zIndex: "100",
                    width: { xs: "calc(100% - 60px)", sm: "260px" },

                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: "space-between",
                        marginBottom: "10px",

                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "800",
                            padding: "0 10px",
                            fontFamily: 'Mona Sans',
                            fontSize: "1.2rem",
                        }}
                    >Logo</Typography>
                    <CloseComp
                        onClick={handleClose}
                    ></CloseComp>
                </Box>

                <Box
                    sx={{
                        width: "300px",
                        height: "90px",
                        cursor: "pointer",
                        pointerEvents: logoName ? "auto" : "none",
                        alignSelf: "center",
                    }}
                    id="logo-canvas"
                    component="canvas"
                >
                </Box>

                <Typography
                    sx={{
                        fontSize: "10px",
                        color: "#9A9A9A",
                        fontFamily: 'Mona Sans',
                    }}
                >*Reposition the logo above by dragging the logo</Typography>
                <Typography
                    sx={{
                        fontWeight: "700",
                        fontFamily: 'Mona Sans',
                    }}
                >Resize Logo</Typography>
                {/* <input
                    style={{
                        width: "100%",
                        color: "black",
                        cursor: "pointer",
                    }}
                    className='slider'
                    type="range"
                    min="25"
                    max="75"
                    defaultValue={50}
                    id="logo-scale"
                /> */}

                <Slider
                    sx={{
                        color: "black",
                        '& .MuiSlider-thumb': {
                            width: 24,
                            height: 24,
                            border: "4px solid white",
                            boxShadow: '0px 0px 4px rgba(0,0,0,0.4)',
                            '&:before': {
                                boxShadow: 'none',
                            },
                            '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                boxShadow: 'none',
                            },
                        },

                    }}

                    id="logo-scale"
                    aria-label="Small"
                    value={logoScale}
                    onChange={onScaleInputHandler}
                    onPointerDown={scaleInputStartHandler}
                    onChangeCommitted={scaleInputendHandler}
                />


                <Typography
                    sx={{
                        fontWeight: "700",
                        fontFamily: 'Mona Sans',

                    }}
                >Upload Your Logo</Typography>
                <Button
                    onClick={() => { logoInputRef.current.click() }}
                    sx={{
                        color: "white",
                        backgroundColor: "black",
                        width: "170px",
                        borderRadius: "100px",
                        padding: "5px 10px",
                        fontFamily: 'Mona Sans',
                        textTransform: "capitalize",
                        fontWeight: "600",
                        transitionProperty: "background-color, box-shadow,transform,filter",
                        transitionDuration: " 0.5s",
                        '&:hover': {
                            backgroundColor: "black",
                            transform: "scale(1.1)",
                        },
                    }}

                    startIcon={<FileUploadIcon></FileUploadIcon>}>Upload Logo

                </Button>
                <input
                    onChange={fileInputChangeHandler}
                    ref={logoInputRef}
                    accept="image/*"
                    type="file"
                    id='logo-file-input'
                    hidden

                />
                <Typography
                    sx={{
                        fontSize: "10px",
                        color: "#9A9A9A",
                        fontFamily: 'Mona Sans',
                    }}
                >*Only JPG or PNG files up to 10MB</Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        fontFamily: 'Mona Sans',
                        backgroundColor: "#F5F5F5",
                        padding: "15px",
                        borderRadius: "50px",

                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "15px",
                            fontFamily: 'Mona Sans',
                        }}
                    >{fileName(logoName)}</Typography>

                    <Box
                        onClick={removeLogoHandler}
                        sx={{
                            width: "12px",
                            cursor: 'pointer',
                            transitionProperty: "transform",
                            transitionDuration: " 0.5s",
                            '&:hover': {
                                transform: "scale(1.2)",
                            },
                        }}
                        component="img"
                        src="./assets/images/icons/Trash.svg"
                    />
                </Box>


            </Box>
        </Grow>
    )

}

export default UploadLogoComp