import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { Box, Snackbar, Grow, Grid, Typography, Card, CardActions, CircularProgress, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { keyframes } from '@mui/system';
import { nanoid } from 'nanoid'
import { QRCodeSVG } from 'qrcode.react';
import CloseComp from '../ConfigurationComp/Utility/CloseComp';

import { uploadData } from '../Modules/DataInputUpload';


function QRCodeComp(props) {
    const [uuid, setUuid] = useState(null)
    const [snackOpen, setSnackOpen] = useState(false)
    const materials = [
        {
            title: "Leather Red 2",
            preview: "./assets/images/materials/material.jpg"
        },
    ]

    const copyShareHandler = () => {
        navigator.clipboard.writeText(window.location);

        setSnackOpen(true)

        setTimeout(() => {
            setSnackOpen(false)
        }, 1000);
    }


    const clossHandler = () => {
        props.setMode(null)
    }

    const spin = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `;


    const uploadDataHandler = async () => {

        console.log(props.configureData)

        const data = await uploadData(props.configureData)

        if (data.uuid) {
            console.log(data)
            setUuid(data.uuid)
        }
    }

    useEffect(() => {
        uploadDataHandler()

    }, [])

    return (
        <Box
            sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: '0',
                left: '0',
                display: 'flex',
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                zIndex: "2000",
                fontFamily: 'Mona Sans',
                backgroundColor: "#000000d4"
            }}
        >

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackOpen}
                message="Link Copied"
            />
            <Grow
                in={true}
            >
                <Box
                    sx={{

                        bottom: "60px",
                        margin: "10px 20px",
                        backgroundColor: "white",
                        borderRadius: "16px",
                        display: 'flex',
                        flexDirection: "column",
                        width: { xs: "calc(100% - 80px)", sm: "420px" },
                        boxShadow: "0px 0px 6px 3px #9f9e9e47",
                        overflow: "hidden",
                        minHeight: "300px",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "20px",
                        position: 'relative',
                        padding: "60px 40px",
                        fontFamily: 'Mona Sans',

                    }}
                >


                    <CloseComp
                        onClick={clossHandler}
                        sx={{
                            position: 'absolute',
                            cursor: 'pointer',
                            right: "0",
                            top: '0',
                            margin: "16px",
                        }}
                    />

                    <Typography
                        sx={{
                            color: "#151515",
                            fontWeight: "900",
                            fontSize: { xs: "1rem", sm: "1.4rem" },
                            fontFamily: 'Mona Sans',
                            textAlign: "center",
                            width: "75%",
                            textTransform: "uppercase",
                            lineHeight: "normal",
                            letterSpacing: "1px",
                        }}
                    >View AIO CUSTOM™
                        <br></br>in Augmented Reality</Typography>

                    <Grow

                        sx={{
                            display: uuid ? "none" : "flex",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                        in={!uuid}>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: "20px"
                            }}
                        >
                            <Box
                                component="img"
                                src="./assets/images/icons/LoadingCircle.svg"
                                sx={{
                                    animation: `${spin} 0.6s linear infinite`
                                }}
                            >
                            </Box>

                            <Typography
                                sx={{
                                    color: "#151515",
                                    fontSize: { xs: "1rem", sm: "1.4rem" },
                                    fontFamily: 'Mona Sans',
                                    fontWeight: "500",
                                    lineHeight: "normal",
                                    letterSpacing: "1px",
                                }}
                            >Generating a preview link...</Typography>

                        </Box>
                    </Grow>
                    <Grow
                        sx={{
                            display: !uuid ? "none" : "flex",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                        in={uuid}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: "24px",

                            }}
                        >
                            <Box
                                sx={{
                                    padding: '10px'
                                }}
                            >
                                <QRCodeSVG
                                    size={100}
                                    value={window.location.href} />

                            </Box>

                            <Typography
                                sx={{
                                    textAlign: "center",
                                    color: "#9A9A9A",
                                    fontFamily: "Mona-Sans",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "normal",
                                    letterSpacing: "-0.5px",
                                }}
                            >
                                Scan QR code or...
                            </Typography>
                            <Box
                                sx={{
                                    color: "black",
                                    fontSize: "0.7rem",
                                    textAlign: "center",
                                    fontFamily: 'Mona Sans',
                                    display: "flex",
                                    gap: "0.2rem"
                                }}
                            ><Typography
                                onClick={copyShareHandler}
                                sx={{
                                    color: " #151515",
                                    textAlign: "center",
                                    leadingTrim: "both",
                                    textEdge: "cap",
                                    fontFeatureSettings: "'dlig' on",
                                    fontFamily: "Mona-Sans",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "20px",
                                    letterSpacing: "1px",
                                    textDecorationLine: "underline",
                                }}
                            >Copy QR Code Link</Typography>
                                <Typography
                                    sx={{
                                        color: " #151515",
                                        textAlign: "center",
                                        leadingTrim: "both",
                                        textEdge: "cap",
                                        fontFeatureSettings: "'dlig' on",
                                        fontFamily: "Mona-Sans",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "20px",
                                        letterSpacing: "1px",
                                    }}
                                >and open link on a mobile device </Typography>
                            </Box>
                            <Button
                                onClick={clossHandler}
                                sx={{

                                    leadingTrim: "both",
                                    textEdge: "cap",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    color: "white",
                                    backgroundColor: "#383A3A", 
                                    textAlign: "center",
                                    fontFamily: 'Mona Sans',
                                    borderRadius: "100px",
                                    margin: "15px 15px",
                                    padding: "12px 20px",
                                    fontWeight: "700",
                                    lineHeight: "normal",
                                    letterSpacing: " 0.5px",
                                    textTransform: "capitalize",
                                    transitionProperty: "background-color, box-shadow,transform,filter",
                                    transitionDuration: " 0.5s",
                                    '&:hover': {
                                        backgroundColor: "#383A3A",
                                        transform: "scale(1.1)",
                                    },
                                }}
                            >Close</Button>
                        </Box>

                    </Grow>
                </Box>
            </Grow >
        </Box >
    )
}

export default QRCodeComp


