import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Box, Button, ButtonGroup, Grid, Typography, Card, CardActions, CardMedia } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


function InteriorExteriorComp(props) {

    // console.log(props.isInterior)
    
    const switchModeHandler = () => {

        props.threejsModule.swichInteroir(!props.isInterior)

        props.setConfigureMode(null)

        // console.log()

        props.setIsInterior(!props.isInterior)

        // if (props.isInterior) {
        //     props.setIsInterior(true)
        //     // window.dispatchEvent(new CustomEvent('change-configurate-mode', { detail: { mode: "enterior" } }));
        // } else {
        //     props.setIsInterior(false)
        //     // window.dispatchEvent(new CustomEvent('change-configurate-mode', { detail: { mode: null } }));
        // }
    }

    const clossHandler = () => {
        // props.setMode(null)
    }

    return (
        <Button

            sx={{
                marginBottom: "20px",
                color: 'white',
                position: "absolute",
                bottom: "0",
                left: "calc(50% - 100px)",
                width: "200px",
                height: "46px",
                padding: "5px",
                border: "2px solid #ffffff00",
                borderRadius: "100px",
                backgroundColor: "#FFFFFF66",
                display: { xs: "none", sm: "flex" },
                justifyContent: " space-evenly",
                '&:hover': {
                    backgroundColor: "#FFFFFF88",
                }
            }}
            onClick={switchModeHandler}
        >

            <Typography
                sx={{
                    zIndex: "1",
                    width: "100px",
                    color: props.isInterior ? "white" : "#383A3A",
                    fontFamily: 'Mona Sans',
                    fontWeight: "700",
                    transition: "transform 0.2s ease-out",
                    textTransform: "capitalize"
                }}
            >

                Exterior
            </Typography>


            <Typography
                sx={{
                    zIndex: "1",
                    width: "100px",
                    color: !props.isInterior ? "white" : "#383A3A",
                    fontFamily: 'Mona Sans',
                    fontWeight: "700",
                    transition: "transform 0.2s ease-out",
                    textTransform: "capitalize"
                }}
            >

                Interior
            </Typography>

            <Box
                sx={{
                    position: 'absolute',
                    left: !props.isInterior ? "0px" : "96px",
                    width: "100px",
                    height: "42px",
                    borderRadius: "100px",
                    backgroundColor: "white",
                    transition: "left .5s",
                }}
            />
        </Button>
    )

}

export default InteriorExteriorComp
