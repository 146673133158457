import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Grow,
  styled,
  Menu,
  MenuItem,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';

import IconButton from '../ConfigurationComp/Utility/IconButtonComp';

import IconButtonCompMobile from '../ConfigurationComp/Utility/IconButtonCompMobile';

function CameraComps(props) {
  const open = props.open;
  const anchorRef = useRef();
  const handleClose = (value) => {
    if (open) {
      props.setFeatureMode(null);
    } else {
      props.setFeatureMode('camera');
    }
  };
  const moveCameraHandler = (position) => {
    props.threejsModule.updateCameraPosition(position);
  };

  // console.log(props.isInterior)

  return (
    <Grow
      in={true}
      style={{
        transformOrigin: '0 0 0',
      }}
      timeout={600}
    >
      <Box
        ref={anchorRef}
        sx={{
          display: props.isInterior ? 'none' : 'block',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            gap: '10px',
            pointerEvents: props.isInterior ? 'none' : 'auto',
          }}
        >
          {!open && (
            <IconButton
              active={true}
              onClick={handleClose}
              component='./assets/images/icons/Camera.svg'
            ></IconButton>
          )}

          {open && (

            <Box
              onClick={handleClose}
              sx={{
                cursor: "pointer",
                width: "48px",
                height: "48px",
                display: 'flex',
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                transition: "transform .3s",
              }}
            >
              <Box
                component='img'
                src="./assets/images/icons/Close.svg"
                sx={{
                  width: "20px",
                  height: "20px",
                  padding: "8px",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  animation: "rotateIn 0.2s linear"
                }}
              >
              </Box>

            </Box>
            // <IconButton
            //   onClick={handleClose}
            //   active={true}
            //   component='./assets/images/icons/Close.svg'
            // ></IconButton>


          )}

          <Grow in={open}>
            <Box>
              <IconButton
                onClick={() => {
                  moveCameraHandler('back-left');
                }}
                active={true}
                component='./assets/images/icons/CarTopAngleBackLeft.svg'
              ></IconButton>
            </Box>
          </Grow>
          <Grow
            in={open}
            style={{ transformOrigin: '0 0 0' }}
            {...(open ? { timeout: 200 } : {})}
          >
            <Box>
              <IconButton
                onClick={() => {
                  moveCameraHandler('back-right');
                }}
                active={true}
                component='./assets/images/icons/CarTopAngleBackRight.svg'
              ></IconButton>
            </Box>
          </Grow>
          <Grow
            in={open}
            style={{ transformOrigin: '0 0 0' }}
            {...(open ? { timeout: 400 } : {})}
          >
            <Box>
              <IconButton
                onClick={() => {
                  moveCameraHandler('front-left');
                }}
                active={true}
                component='./assets/images/icons/CarTopAngleFrontLeft.svg'
              ></IconButton>
            </Box>
          </Grow>
          <Grow
            in={open}
            style={{ transformOrigin: '0 0 0' }}
            {...(open ? { timeout: 600 } : {})}
          >
            <Box>
              <IconButton
                onClick={() => {
                  moveCameraHandler('front-right');
                }}
                active={true}
                component='./assets/images/icons/CarTopAngleFrontRight.svg'
              ></IconButton>
            </Box>
          </Grow>
        </Box>
      </Box>
    </Grow>
  );
}

export default CameraComps;

