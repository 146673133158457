import { Box, Button } from "@mui/material";



function IconButtonCompMobile(props) {

    return (
        <Button
            onClick={props.onClick}
            sx={{
                position: "relative",
                width: "60px",
                height: "60px",
                color: "black",
                fontDamily: "Rowdies",
                borderRadius: "100px",
                minWidth: "20px",
                border: "1px solid black",
                backgroundColor: "white",
                textTransform: "capitalize",
                transitionProperty: "background-color, box-shadow,transform,filter",
                transitionDuration: " 0.5s",
                '&:hover': {
                    backgroundColor: "white",
                    transform: "scale(1.1)",
                },
                '&:hover img': {
                    transitionProperty: "filter",
                    transitionDuration: " 0.5s",
                },
                '& img': {
                    transitionProperty: "background-color, box-shadow,transform,filter",
                    transitionDuration: " 0.5s",
                },
                "&::before":
                    props.border && {
                        content: '" "',
                        position: "absolute",
                        top: "-4px",
                        zIndex: "-1",
                        backgroundColor: " #ffffff75",
                        width: " 100%",
                        height: "100%",
                        borderRadius: "100px",
                        padding: "4px",

                    }

            }}
        >
            <Box
                sx={{
                    width: "30px",
                }}
                component="img"
                src={props.component}
            />
        </Button >
    )

}

export default IconButtonCompMobile