import React, { useEffect, useRef, useState, useContext } from 'react';
import {
  Box,
  Button,
  Paper,
  Grow,
  styled,
  Menu,
  MenuItem,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import ColorSelector from './ColorComp/ColorSelector';
import WheelComp from './WheelComp/WheelComp';
import UploadLogoComp from './UploadLogoComp/UploadLogoComp';

import IconButton from './Utility/IconButtonComp';
import InteriorComp from './InteriorComp';

import AIOContext from '../../AIOContext';

const CustomButton = styled(Button)(({ theme }) => ({
  height: '40px',
  color: '#383A3A',
  fontFamily: 'Mona Sans',
  fontWeight: '700',
  borderRadius: '100px',
  backgroundColor: 'white',
  padding: '0 20px',
  textTransform: 'capitalize',
  '&::after': {
    content: '" "',
    position: 'absolute',
    zIndex: -1,
    display: 'block',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    borderRadius: '100px',
    backgroundColor: 'white',
    transitionProperty: 'background-color, box-shadow,transform,filter',
    transitionDuration: '0.5s',
  },
  '&:hover': {
    backgroundColor: 'white',
    '&::after': {
      transform: 'scale(1.1)',
    },
  },
}));

// const IconButton = styled(Button)(({ theme }) => ({
//     width: "40px",
//     height: "40px",
//     color: "#383A3A",
//     fontFamily: 'Mona Sans',
//     fontWeight: "600",
//     borderRadius: "100px",

//     minWidth: "20px",
//     border: "1px solid white",
//     backgroundColor: "#FFFFFF33",
//     textTransform: "capitalize",
//     '&:hover': {
//         backgroundColor: "#FFFFFF33",
//     }
// }));

function ConfigureComp(props) {
  const [exteriorMode, setExteriorMode] = useState(true);
  // console.log('=== CONFIGURE ===')
  // console.log(props)

  const { vehicle } = useContext(AIOContext);
  // const [configureMode, setConfigureMode] = useState(null)
  // const [configureMode, setConfigureMode] = useState(null)
  const [configureOpen, setConfigureOpen] = useState(false);

  const configureMode = props.configureMode;
  const setConfigureMode = props.setConfigureMode;

  // const [checked, setChecked] = useState(false)

  const [headLight, setHeadLight] = useState(true);

  const anchorRef = useRef();

  // configureData={props.configureData}
  // console.log(props.configureData.colors.accent)

  const handleClose = () => {
    setConfigureOpen(!configureOpen);
  };

  const handleSave = () => {
    props.setSaveMode(true);
  };

  const showUIHanlder = (value) => {
    console.log(value);
    setConfigureMode(value);
  };

  useEffect(() => {
    vehicle.setVehicleConfig(() => ({
      ...props.configureData,
    }));
  }, []);

  // useEffect(() => {
  //     window.addEventListener("change-configurate-mode", (e) => {
  //         // const mode = e.detail.mode
  //         // setConfigureMode(mode)

  //     })

  // }, [])

  return (
    <React.Fragment>
      <Box
        ref={anchorRef}
        sx={{
          position: 'absolute',
          bottom: '0',
          margin: '20px',
          display: { xs: 'none', sm: 'block' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            margin: '20px 0',
            flexDirection: 'column-reverse',
            gap: '10px',
          }}
        >
          <Grow in={configureOpen}>
            {
              <Box
                sx={{
                  display:
                    props.configureData.carType === 'van' && !props.isInterior
                      ? 'block'
                      : 'none',
                  pointerEvents:
                    props.configureData.carType === 'van' ? 'auto' : 'none',
                }}
              >
                <IconButton
                  active={true}
                  onClick={() => {
                    showUIHanlder('logo');
                  }}
                  component='./assets/images/icons/AddImage.svg'
                ></IconButton>
              </Box>
            }
          </Grow>
          <Grow
            in={configureOpen}
            style={{ transformOrigin: '0 0 0' }}
            {...(configureOpen ? { timeout: 200 } : {})}
          >
            <Box
              sx={{
                display: !props.isInterior ? 'block' : 'none',
              }}
            >
              <IconButton
                active={true}
                onClick={() => {
                  showUIHanlder('wheel');
                }}
                component='./assets/images/icons/Car-Tool-Steering-Wheel.svg'
              ></IconButton>
            </Box>
          </Grow>
          <Grow
            in={configureOpen}
            style={{ transformOrigin: '0 0 0' }}
            {...(configureOpen ? { timeout: 400 } : {})}
          >
            <Box
              sx={{
                display: !props.isInterior ? 'block' : 'none',
              }}
            >
              <IconButton
                active={true}
                onClick={() => {
                  showUIHanlder('color');
                }}
                component='./assets/images/icons/ColorPaletteSample1.svg'
              ></IconButton>
            </Box>
          </Grow>
          <Grow
            in={configureOpen}
            style={{ transformOrigin: '0 0 0' }}
            {...(configureOpen ? { timeout: 400 } : {})}
          >
            <Box
              sx={{
                display: props.isInterior ? 'block' : 'none',
              }}
            >
              <IconButton
                active={true}
                onClick={() => {
                  showUIHanlder('interior');
                }}
                component='./assets/images/icons/ColorPaletteSample1.svg'
              ></IconButton>
            </Box>
          </Grow>
        </Box>

        {!configureOpen ? (
          <CustomButton
            onClick={handleClose}
            sx={{}}
            startIcon={<SettingsIcon />}
          >
            Customize
          </CustomButton>
        ) : (
          <CustomButton onClick={handleClose} sx={{}} startIcon={<CloseIcon />}>
            Close
          </CustomButton>
        )}

        {props.reservationData && (
          <CustomButton
            onClick={handleSave}
            sx={{
              marginLeft: '20px',
            }}
            startIcon={<SaveIcon />}
          >
            Save
          </CustomButton>
        )}
      </Box>
      {/* {!configureMode && <ConfigureComp configureOpen={configureOpen} setConfigureOpen={setConfigureOpen} threejsModule={props.threejsModule} setConfigureMode={setConfigureMode} />} */}
      {configureMode === 'color' && (
        <ColorSelector
          configureData={props.configureData}
          threejsModule={props.threejsModule}
          setConfigureMode={setConfigureMode}
        />
      )}
      {configureMode === 'wheel' && (
        <WheelComp
          configureData={props.configureData}
          threejsModule={props.threejsModule}
          setConfigureMode={setConfigureMode}
        />
      )}
      {configureMode === 'interior' && (
        <InteriorComp
          configureData={props.configureData}
          threejsModule={props.threejsModule}
          setConfigureMode={setConfigureMode}
        />
      )}
      {/* <UploadLogoComp configureData={props.configureData} threejsModule={props.threejsModule} setConfigureMode={setConfigureMode} configureMode={configureMode} /> */}
    </React.Fragment>
  );
}

export default ConfigureComp;
