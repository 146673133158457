import React, { useEffect, useContext } from 'react';
import AIOContext from '../../AIOContext';
import { Box, Grow, Typography, Button } from '@mui/material';
import CloseComp from '../ConfigurationComp/Utility/CloseComp';

function OnMobileDeviceComp(props) {
  const clossHandler = () => {
    props.setMode(null);
  };

  const { updateReservation } = useContext(AIOContext);

  const handleSave = async () => {
    await updateReservation();

    props.setMode(false);
    props.setSuccess(true);
  };

  useEffect(() => {}, []);

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '2000',
        fontFamily: 'Mona Sans',
        backgroundColor: '#000000d4',
      }}
    >
      <Grow in={true}>
        <Box
          sx={{
            bottom: '60px',
            // margin: "10px 20px",
            backgroundColor: 'white',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0px 0px 6px 3px #9f9e9e47',
            overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '25px',
            position: 'relative',
            padding: '40px 40px',
            fontFamily: 'Mona Sans',
            maxWidth: '600px',
            margin: '0 30px',
          }}
        >
          <CloseComp
            onClick={clossHandler}
            sx={{
              position: 'absolute',
              cursor: 'pointer',
              right: '0',
              top: '0',
              margin: '16px',
            }}
          />

          <Typography
            sx={{
              color: '#151515',
              fontWeight: '900',
              fontSize: '24px',
              fontFamily: 'Mona Sans',
              textAlign: 'center',
              width: '75%',
              textTransform: 'uppercase',
              lineHeight: '90%',
              letterSpacing: '1px',
            }}
          >
            On a mobile device?
          </Typography>

          <Typography
            sx={{
              fontSize: '14px',
              textAlign: 'center',
              fontFamily: 'Mona Sans',
            }}
          >
            Please use a laptop or desktop computer to download and unzip images
          </Typography>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Button
              onClick={() => {
                props.setMode(false);
              }}
              sx={{
                color: 'white',
                backgroundColor: '#383A3A',
                fontSize: { xs: '10px', sm: '14px' },
                textAlign: 'center',
                fontFamily: 'Mona Sans',
                borderRadius: '100px',
                margin: '15px 15px',
                padding: '12px 20px',
                fontWeight: '700',
                lineHeight: 'normal',
                letterSpacing: ' 0.5px',
                textTransform: 'capitalize',
                zIndex: '1',
                cursor: 'pointer',
                position: 'relative',

                '&::after': {
                  content: '" "',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  zIndex: '-1',
                  display: 'block',
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                  borderRadius: '100px',
                  backgroundColor: '#383A3A',
                  transitionProperty:
                    'background-color, box-shadow,transform,filter',
                  transitionDuration: '0.5s',
                },
                '&:hover': {
                  '&::after': {
                    transform: 'scale(1.1)',
                    backgroundColor: '#383A3A',
                  },
                },
              }}
            >
              Got it
            </Button>
          </Box>
        </Box>
      </Grow>
    </Box>
  );
}

export default OnMobileDeviceComp;
