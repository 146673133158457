// MyProvider.js
import React, { useEffect, useState } from 'react';
import AIOContext from './AIOContext';
import apiURL from './util/apiURL.js';
import { isMobile } from 'react-device-detect';

const AIOProvider = ({ children, configureData, threejsModule }) => {
  const [interiorConfig, setInteriorConfig] = useState({});
  const [exteriorConfig, setExteriorConfig] = useState({});
  // const [dapChecked, setDapChecked] = useState(false);
  const [dueAmount, setDueAmount] = useState(10000);
  const [vehicleSubtotal, setVehicleSubtotal] = useState(0);
  // const [isFleet, setIsFleet] = useState(true);
  const [logoData, setLogoData] = useState('');
  // const [vehicleQuantities, setVehicleQuantities] = useState({
  //   car: 1,
  //   truck: 1,
  //   van: 1,
  // });

  const [userConfig, setUserConfig] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    emailAddress: '',
    dapPurchased: false,
    // amountPaid: 0,
    // stripePaymentID: '',
    // stripeLastFour: '',
    // stripePaymentType: '',
  });

  const [vehicleConfig, setVehicleConfig] = useState(configureData);

  const totalVehicles = Object.values(vehicleConfig.vehicleQuantities).reduce(
    (total, quantity) => {
      if (isNaN(quantity)) {
        return total;
      }
      return total + parseInt(quantity, 10);
    },
    0
  );

  const totalVehicleTypesSelected = Object.values(
    vehicleConfig.vehicleQuantities
  ).reduce((total, quantity) => {
    if (quantity > 0) {
      return total + 1;
    } else {
      return total;
    }
  }, 0);

  const totalDapAmount = vehicleConfig.isFleet
    ? totalVehicleTypesSelected * 1000
    : 1000;

  const baseAmount = 10000;
  const baseDapCost = 1000;

  // automatically recalculate dueAmount whenever dapChecked or totalVehicles changes
  useEffect(() => {
    let amount = baseAmount;
    if (vehicleConfig.isFleet) {
      if (totalVehicles > 1) {
        amount = amount * totalVehicles;
      }
      setVehicleSubtotal(amount);
      if (userConfig.dapPurchased) {
        amount = amount + totalDapAmount;
      }
    } else {
      setVehicleSubtotal(amount);
      if (userConfig.dapPurchased) {
        amount = amount + baseDapCost;
      }
    }
    setDueAmount(amount);
  }, [userConfig.dapPurchased, totalVehicles, vehicleConfig, totalDapAmount]);

  const toggleDap = () => {
    setUserConfig({ ...userConfig, dapPurchased: !userConfig.dapPurchased });
    // setDapChecked(!dapChecked);
  };

  const updateReservation = async () => {
    const emailImage = isMobile ? '' : threejsModule.renderFeatureImage();

    setUserConfig((prevState) => ({
      ...prevState,
      dapPurchased: true,
      dap: true,
      updatedAt: new Date(),
    }));

    const payload = {
      aioVerseUrl: userConfig.uuid,
      user: userConfig,
      vehicle: vehicleConfig,
      dap: {
        dapChecked: true,
        totalDapAmount: totalDapAmount,
      },
      customImage: emailImage,
      logoData: logoData,
      sendUpdate: false,
      fleet: {
        isFleet: vehicleConfig.isFleet,
        totalVehicles: totalVehicles,
        totalVehicleTypesSelected: totalVehicleTypesSelected,
        vehicleSubtotal: vehicleSubtotal,
      },
    };

    const response = await fetch(`${apiURL}/api/reservations/standard`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    return response;
  };

  return (
    <AIOContext.Provider
      value={{
        interior: { interiorConfig, setInteriorConfig },
        exterior: { exteriorConfig, setExteriorConfig },
        user: { userConfig, setUserConfig },
        vehicle: { vehicleConfig, setVehicleConfig },
        dap: { dapChecked: userConfig.dapPurchased, toggleDap, totalDapAmount },
        dueAmount,
        fleet: {
          isFleet: vehicleConfig.isFleet,
          totalVehicles,
          totalVehicleTypesSelected,
          vehicleSubtotal,
        },
        logo: {
          logoData,
          setLogoData,
        },
        updateReservation,
      }}
    >
      {children}
    </AIOContext.Provider>
  );
};

export default AIOProvider;
