import React, { useState } from 'react';
import {
  Box,
  Button,
  styled,
  Avatar,
  Typography,
  Collapse,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import { ga } from 'react-ga';

function SwitchCarCompMobile(props) {
  // const [mode, setMode] = useState(props.configureData.carType)
  const [open, setOpen] = useState(false);

  const closeHander = () => {
    setOpen(!open);
  };

  const queryParams = new URLSearchParams(window.location.search);

  const isReservation = queryParams.has('reservation');

  const { vehicleQuantities } = props;

  let showCar = true;
  let showTruck = true;
  let showVan = true;

  if (vehicleQuantities.van === 0 && isReservation) {
    showVan = false;
  }

  if (vehicleQuantities.truck === 0 && isReservation) {
    showTruck = false;
  }

  if (vehicleQuantities.car === 0 && isReservation) {
    showCar = false;
  }

  const CustomButton = styled(Button)(({ theme }) => ({
    height: '40px',
    color: '#383A3A',
    fontFamily: 'Mona Sans',
    fontWeight: '600',
    borderRadius: '100px',
    backgroundColor: 'white',
    padding: '0 20px',
    textTransform: 'capitalize',
    // transitionProperty: "background-color, box-shadow,transform,filter",
    // transitionDuration: "0.5s",
    // '&:hover': {
    //     backgroundColor: "white",
    //     transform: "scale(1.1)",
    // },

    '&::after': {
      content: '" "',
      position: 'absolute',
      zIndex: -1,
      display: 'block',
      width: '100%',
      height: '100%',
      borderRadius: '100px',
      backgroundColor: 'white',
      transitionProperty: 'background-color, box-shadow,transform,filter',
      transitionDuration: '0.5s',
    },
    '&:hover': {
      backgroundColor: 'white',
      '&::after': {
        transform: 'scale(1.1)',
      },
    },
  }));

  return (
    <Box
      sx={{
        marginLeft: `${props.expand ? '-200px' : '0'}`,
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        zIndex: '100',
        transitionProperty: 'margin-left',
        transitionDuration: ' 0.5s',
      }}
    >
      <CustomButton
        onClick={closeHander}
        sx={{
          // fontFamily: 'Mona Sans',
          // fontWeight: "600",
          width: '100px',
          // justifyContent: "flex-end",
          // boxShadow: open ? "0px 0px 0px 4px white" : "none",
        }}
        endIcon={
          <ArrowDropDownIcon
            sx={{
              transform: open ? 'rotate(180deg)' : 'rotate(0)',
              transition: 'transform 2s ease-out',
            }}
          />
        }
      >
        <Box
          sx={{
            width: '50px',
            textAlign: 'start',
            fontWeight: '800',
          }}
        >
          {props.carMode}
        </Box>
      </CustomButton>

      <Collapse in={open}>
        <Box
          sx={{
            borderRadius: '10px',
            backgroundColor: 'white',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {showCar && (
            <Card
              setOpen={setOpen}
              threejsModule={props.threejsModule}
              onClick={props.setCarMode}
              mode={props.carMode}
              name='car'
              url='./assets/images/cars/suv.png'
            />
          )}
          {showTruck && (
            <Card
              setOpen={setOpen}
              threejsModule={props.threejsModule}
              onClick={props.setCarMode}
              mode={props.carMode}
              name='truck'
              url='./assets/images/cars/truck.png'
            />
          )}
          {showVan && (
            <Card
              setOpen={setOpen}
              threejsModule={props.threejsModule}
              onClick={props.setCarMode}
              mode={props.carMode}
              name='van'
              url='./assets/images/cars/van.png'
            />
          )}
        </Box>
        <Box
          onClick={closeHander}
          sx={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            zIndex: '-1',
            backgroundColor: '#090b12c2',
          }}
        ></Box>
      </Collapse>
    </Box>
  );
}

export default SwitchCarCompMobile;

const Card = (props) => {
  const onClickHandler = () => {
    props.threejsModule.switchCar(props.name);
    props.onClick(props.name);
    props.setOpen(false);
  };
  return (
    <Box
      onClick={onClickHandler}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        backgroundColor: props.mode === props.name ? '#E9E9E9' : 'none',
        padding: '5px',
        '&:hover': {
          backgroundColor: '#E9E9E9',
          cursor: 'pointer',
        },
      }}
    >
      <Box
        sx={{
          width: '100px',
          transition: 'transform 0.2s ease-out',
          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
        component='img'
        src={props.url}
      />
      <Typography
        sx={{
          fontWeight: '600',
          fontFamily: 'Mona Sans',
          textTransform: 'uppercase',
          fontSize: '20px',
          fontWeight: '900',
          lineHeight: 'normal',
          letterSpacing: '-0.5px',
        }}
      >
        {props.name}
      </Typography>
    </Box>
  );
};
