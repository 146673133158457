import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, styled, Avatar } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';
import ConfigureComp from './ConfigurationComp/ConfigureComp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ReservationForm from './ReservationForm';
import './FormComp/styles/misc.css';

import CameraComps from './FeatureComps/CameraComps';
import DoorOpenComp from './FeatureComps/DoorOpenComp';
import SceneComp from './FeatureComps/SceneComp';
import QRCodeComp from './FeatureComps/QRCodeComp';
import InteriorExteriorComp from './FeatureComps/InteriorExteriorComp';

import SwitchCarComp from './ConfigurationComp/SwitchCarComponents/SwitchCarComp';
import SwitchCarCompMobile from './ConfigurationComp/SwitchCarComponents/SwitchCarCompMobile';
import ExitComp from './ExitComp/ExitComp';
import SaveComp from './SaveComp/SaveComp';
import SaveSuccessComp from './SaveSuccessComp/SaveSuccessComp';

import SceneConfigureCompMobile from './FeatureComps/SceneConfigureCompMobile';
import SceneConfigureComp from './FeatureComps/SceneConfigureComp';

import ConfigureCompMobile from './ConfigurationComp/ConfigureCompMobile';

import UploadLogoComp from './ConfigurationComp/UploadLogoComp/UploadLogoComp';

import IconButton from './ConfigurationComp/Utility/IconButtonComp';

import ExpandButton from './ConfigurationComp/Utility/ExpandButton';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import AIOContext from '../AIOContext.js';
import OnMobileDeviceComp from './OnMobileDeviceComp/OnMobileDeviceComp.js';

const CustomButton = styled(Button)(({ theme }) => ({
  height: '40px',
  color: '#383A3A',
  fontFamily: 'Mona Sans',
  fontWeight: '700',
  borderRadius: '100px',
  backgroundColor: 'white',
  padding: '0 20px',
  textTransform: 'capitalize',
  cursor: 'pointer',
  // overflow:"hidden",
  '&::after': {
    content: '" "',
    position: 'absolute',
    zIndex: -1,
    display: 'block',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    borderRadius: '100px',
    backgroundColor: 'white',
    transitionProperty: 'background-color, box-shadow,transform,filter',
    transitionDuration: '0.5s',
  },
  '&:hover': {
    '&::after': {
      transform: 'scale(1.1)',
      backgroundColor: 'white',
    },
  },
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function UIcomponent(props) {
  const { dueAmount, vehicle, user, updateReservation } =
    useContext(AIOContext);

  const { userConfig, setUserConfig } = user;

  const { vehicleConfig, setVehicleConfig } = vehicle;

  useEffect(() => {
    if (props.reservationData) {
      setUserConfig({
        ...userConfig,
        ...props.reservationData,
      });

      setShowForm(true);
    }
  }, [props.reservationData]);

  const options = {
    mode: 'payment',
    amount: dueAmount,
    currency: 'usd',
    paymentMethodCreation: 'manual',
    appearance: {
      theme: 'flat',
      variables: {
        borderRadius: '8px',
        fontFamily: '"Mona Sans", system-ui, sans-serif',
        colorText: '#151515',
        colorBackground: '#F1F1F1',
        spacingUnit: '4px',
        fontVariationSettings: '"wdth" 125',
      },
      rules: {
        '.Input': {
          fontWeight: '700',
        },
      },
    },
    fonts: [
      {
        cssSrc: 'https://aioverse.aiomotors.com/assets/fonts/StripeFonts.css',
      },
    ],
  };

  const [headLight, setHeadLight] = useState(true);
  const [featureMode, setFeatureMode] = useState(null);
  const [qrcode, setQrCode] = useState(false);
  const [isInterior, setIsInterior] = useState(false);

  const [carMode, setCarMode] = useState(props.configureData.carType);

  useEffect(() => {
    setVehicleConfig({
      ...vehicleConfig,
      carType: carMode,
      isFleet: props.isFleet,
    });
  }, [carMode, props.isFleet]);

  useEffect(() => {
    setVehicleConfig(() => ({
      ...props.configureData,
    }));
  }, [props.configureData]);

  const [exitMode, setExitMode] = useState(false);

  const [saveMode, setSaveMode] = useState(false);

  const [onMobileDialogMode, setOnMobileDialogMode] = useState(false);

  const [saveSuccessMode, setSaveSuccessMode] = useState(false);

  const [configurateOpen, setConfigurateOpen] = useState(false);

  const [expand, setExpand] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const formParam = urlParams.get('form');
  const [showForm, setShowForm] = useState(formParam === 'true');

  const expandHandler = () => {
    setExpand(!expand);
  };

  const heightLightHandler = () => {
    props.threejsModule.headLight(headLight);
    setHeadLight(!headLight);
  };
  const handleShowForm = () => {
    setShowForm((prevShowForm) => !prevShowForm);
  };

  const swichInteroir = (value) => {
    console.log(value);
  };

  const handleSave = () => {
    setSaveMode(true);
  };

  const exportImages = () => {
    // props.threejsModule.renderPreview()

    // window.dispatchEvent
    window.dispatchEvent(
      new CustomEvent('webxr-render-image', {
        detail: {
          vehicles: {
            car: true,
            truck: true,
            van: true,
          },
        },
      })
    );

    // webxr-render-image
  };

  const powerOffHandler = () => {
    props.threejsModule.powerOff();
  };

  const startAR = async () => {
    const anchor = document.createElement('a');
    const is_IosAR_supported = anchor.relList.supports('ar');
    // console.log({ is_IosAR_supported });

    const is_webXRAR_supported = window.navigator.xr
      ? await window.navigator?.xr.isSessionSupported('immersive-ar')
      : false;

    // console.log({ is_webXRAR_supported });

    // ios

    if (is_IosAR_supported) {
      console.log('enter AR using AR quick look');
      props.threejsModule.enterAR('ios');
    } else if (is_webXRAR_supported) {
      console.log('enter AR using WebXR API');
      props.threejsModule.enterAR('webxr');
      // setQrCode(true);
    } else {
      setQrCode(true);
      // props.threejsModule.enterAR()
    }

    // fontFamily: 'Mona Sans',
  };

  return (
    <React.Fragment>
      {exitMode && <ExitComp setMode={setExitMode} />}
      {saveMode && (
        <SaveComp setMode={setSaveMode} setSuccess={setSaveSuccessMode} />
      )}
      {saveSuccessMode && <SaveSuccessComp setMode={setSaveSuccessMode} />}
      {onMobileDialogMode && (
        <OnMobileDeviceComp setMode={setOnMobileDialogMode} />
      )}
      {qrcode && (
        <QRCodeComp configureData={props.configureData} setMode={setQrCode} />
      )}

      <Box
        sx={{
          position: 'absolute',
          top: '0',
          margin: '20px',
          fontFamily: 'Mona Sans',
          fontWeight: { xs: '800', sm: '600' },
          display: { xs: 'flex', sm: 'none' },
          gap: '10px',
        }}
      >
        {!configurateOpen && !props.reservationData && (
          <CustomButton
            onClick={() => {
              setExitMode(true);
            }}
            sx={{
              fontFamily: 'Mona Sans',
              fontWeight: { xs: '800', sm: '700' },
              transitionProperty: 'left',
              transitionDuration: ' 0.5s',
              left: `${expand ? '-200px' : '0'}`,
            }}
            startIcon={
              <Box component='img' src='./assets/images/icons/Exit.svg' />
            }
          >
            Exit
          </CustomButton>
        )}
        {((props.reservationData && vehicle.vehicleConfig.isFleet) ||
          !props.reservationData) &&
          configurateOpen && (
            <SwitchCarCompMobile
              expand={expand}
              configurateOpen={configurateOpen}
              setCarMode={setCarMode}
              carMode={carMode}
              threejsModule={props.threejsModule}
              configureData={props.configureData}
              vehicleQuantities={vehicleConfig.vehicleQuantities}
            />
          )}
      </Box>
      <div
        className={`transition-opacity ${
          showForm && `opacity-0 pointer-events-none`
        }`}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            margin: '20px',
            fontFamily: 'Mona Sans',
            fontWeight: '800',
            display: { xs: 'flex', sm: 'none' },
            gap: '20px',
            alignItems: 'center',
          }}
        >
          {props.reservationData && (
            <CustomButton onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </CustomButton>
          )}
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: '0',
            margin: '20px',
            fontFamily: 'Mona Sans',
            fontWeight: '800',
            display: { xs: 'none', sm: 'flex' },
            gap: '20px',
            alignItems: 'center',
          }}
        >
          {!props.reservationData ? (
            <CustomButton
              onClick={() => {
                setExitMode(true);
              }}
              sx={{
                fontFamily: 'Mona Sans',
                fontWeight: { xs: '800', sm: '700' },
              }}
              startIcon={
                <Box component='img' src='./assets/images/icons/Exit.svg' />
              }
            >
              Exit
            </CustomButton>
          ) : (
            // a white pill with the aio color logo in it
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                // backgroundColor: '#fff',
                // borderRadius: '40px',
                // padding: '6px 16px',
              }}
            >
              <CustomButton
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  backgroundColor: '#fff',
                  borderRadius: '40px',
                  padding: '6px 16px',
                  height: 'initial',
                }}
                onClick={() => {
                  setExitMode(true);
                }}
              >
                <img
                  src='./assets/images/AIOLogoColor.png'
                  className='w-[70px] md:w-[100px] h-auto block'
                  alt='AIO Logo'
                />
              </CustomButton>
            </Box>
          )}
          {((props.reservationData && vehicle.vehicleConfig.isFleet) ||
            !props.reservationData) && (
            <SwitchCarComp
              setCarMode={setCarMode}
              carMode={carMode}
              threejsModule={props.threejsModule}
              configureData={props.configureData}
              vehicleQuantities={vehicleConfig.vehicleQuantities}
            />
          )}
        </Box>
      </div>
      <div
        className={`transition-opacity ${
          showForm && `opacity-0 pointer-events-none`
        }`}
      >
        <ConfigureComp
          configureMode={props.configureMode}
          setConfigureMode={props.setConfigureMode}
          isInterior={props.isInterior}
          configureData={props.configureData}
          threejsModule={props.threejsModule}
          setSaveMode={setSaveMode}
          reservationData={props.reservationData}
        />
        <ConfigureCompMobile
          expand={expand}
          setIsInterior={props.setIsInterior}
          configureMode={props.configureMode}
          setConfigureMode={props.setConfigureMode}
          isInterior={props.isInterior}
          configureData={props.configureData}
          threejsModule={props.threejsModule}
        />
        <InteriorExteriorComp
          threejsModule={props.threejsModule}
          setConfigureMode={props.setConfigureMode}
          isInterior={props.isInterior}
          setIsInterior={props.setIsInterior}
          swichInteroir={swichInteroir}
        />

        <UploadLogoComp
          configureData={props.configureData}
          threejsModule={props.threejsModule}
          setConfigureMode={props.setConfigureMode}
          configureMode={props.configureMode}
        />

        <ExpandButton
          expand={expand}
          onClick={expandHandler}
          active={true}
          component={`./assets/images/icons/${
            expand ? 'Shrink.svg' : 'Expand.svg'
          }`}
        ></ExpandButton>
      </div>
      <CustomButton
        onClick={handleShowForm}
        sx={{
          position: 'absolute',
          bottom: '0',
          right: `${expand ? '-200px' : '0'}`,
          marginRight: '20px',
          marginBottom: { xs: '120px', sm: '20px' },
          fontFamily: 'Mona Sans',
          fontWeight: '800',
          transitionProperty: 'right',
          transitionDuration: ' 0.5s',
        }}
        endIcon={
          <Box component='img' src='./assets/images/icons/ArrowRight.svg' />
        }
      >
        {props.reservationData ? 'My Reservation' : 'Reserve'}
      </CustomButton>
      <Elements stripe={stripePromise} options={options}>
        <div className={`form-container ${showForm ? 'show' : ''}`}>
          <ReservationForm
            threejsModule={props.threejsModule}
            configureData={props.configureData}
            formVisible={showForm} // Pass the form visibility state
            toggleFormVisibility={handleShowForm} // Pass the toggleFormVisibility function
            reservationData={props.reservationData}
            setReservationData={props.setReservationData}
            setOnMobileDialogMode={setOnMobileDialogMode}
          />
        </div>
      </Elements>
      <div
        className={`transition-opacity ${
          showForm && `opacity-0 pointer-events-none`
        }`}
      >
        <SceneConfigureComp
          isFleet={props.isFleet}
          startAR={startAR}
          heightLightHandler={heightLightHandler}
          isInterior={props.isInterior}
          carMode={carMode}
          threejsModule={props.threejsModule}
          featureMode={featureMode}
          setFeatureMode={setFeatureMode}
          powerOffHandler={powerOffHandler}
        />
        <SceneConfigureCompMobile
          isFleet={props.isFleet}
          expand={expand}
          setConfigurateOpen={setConfigurateOpen}
          startAR={startAR}
          heightLightHandler={heightLightHandler}
          isInterior={props.isInterior}
          carMode={carMode}
          threejsModule={props.threejsModule}
          featureMode={featureMode}
          setFeatureMode={setFeatureMode}
          powerOffHandler={powerOffHandler}
        />
      </div>
      {/* <Box
                sx={{
                    position: "absolute",
                    right: "0",
                    top: '0',
                    display: "flex",
                    margin: "20px",
                    flexDirection: "column",
                    alignItems: "flex-end   ",
                    gap: "10px"

                }}
            >


                <SceneComp threejsModule={props.threejsModule} open={featureMode === "scene"} setFeatureMode={setFeatureMode} />
                <DoorOpenComp carMode={carMode} threejsModule={props.threejsModule} open={featureMode === "door"} setFeatureMode={setFeatureMode} />
                <CameraComps isInterior={props.configureData.isInterior} threejsModule={props.threejsModule} open={featureMode === "camera"} setFeatureMode={setFeatureMode} />
                
                <IconButton
                    onClick={heightLightHandler}
                    component="./assets/images/icons/Headlight.svg"
                >
                </IconButton>

                <IconButton
                    onClick={startAR}
                    component="./assets/images/icons/3D.svg"
                >
                </IconButton>

            </Box> */}

      {urlParams.get('dev') && (
        <div
          style={{
            position: 'fixed',
            bottom: '0',
            left: '0',
            zIndex: '10000',
            backgroundColor: '#fff',
            padding: '20px',
            fontFamily: 'Mona Sans',
            fontWeight: '800',
            fontSize: '1.5rem',
          }}
        >
          <button
            onClick={() => {
              const imageData = props.threejsModule.renderFeatureImage(
                vehicleConfig.carType
              );
              const link = document.createElement('a');
              link.download = 'image.png';
              link.href = imageData;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          >
            Generate email image
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

export default UIcomponent;
