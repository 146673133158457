import React, { useEffect, useState, useContext } from 'react';
import AIOContext from '../../AIOContext';
import { ChromePicker } from 'react-color';
import {
  Box,
  Snackbar,
  Grow,
  Grid,
  Typography,
  Card,
  CardActions,
  CircularProgress,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { keyframes } from '@mui/system';
import { nanoid } from 'nanoid';
import { QRCodeSVG } from 'qrcode.react';
import CloseComp from '../ConfigurationComp/Utility/CloseComp';

import { uploadData } from '../Modules/DataInputUpload';

function SaveSuccessComp(props) {
  const clossHandler = () => {
    props.setMode(null);
  };

  const { updateReservation } = useContext(AIOContext);

  const handleSave = async () => {
    await updateReservation();

    props.setMode(false);
    props.setSuccess(true);
  };

  useEffect(() => {}, []);

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '2000',
        fontFamily: 'Mona Sans',
        backgroundColor: '#000000d4',
      }}
    >
      <Grow in={true}>
        <Box
          sx={{
            bottom: '60px',
            // margin: "10px 20px",
            backgroundColor: 'white',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0px 0px 6px 3px #9f9e9e47',
            overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '25px',
            position: 'relative',
            padding: '10px 30px',
            fontFamily: 'Mona Sans',
            maxWidth: '600px',
            margin: '0 30px',
          }}
        >
          <CloseComp
            onClick={clossHandler}
            sx={{
              position: 'absolute',
              cursor: 'pointer',
              right: '0',
              top: '0',
              margin: '16px',
            }}
          />

          <div className='flex justify-center'>
            <svg
              width='230'
              height='230'
              viewBox='0 0 230 230'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle
                opacity='0.1'
                cx='115.001'
                cy='115'
                r='91.7982'
                fill='#38B8AB'
              />
              <circle opacity='0.1' cx='115' cy='115' r='115' fill='#38B8AB' />
              <circle cx='115.002' cy='115' r='67.5877' fill='#38B8AB' />
              <circle cx='115' cy='115' r='49.4298' fill='white' />
              <g clipPath='url(#clip0_2701_18599)'>
                <path
                  d='M115 65.5701C87.7012 65.5701 65.5703 87.7017 65.5703 115.001C65.5703 142.301 87.7012 164.433 115 164.433C142.299 164.433 164.43 142.301 164.43 115.001C164.43 87.7017 142.299 65.5701 115 65.5701ZM142.066 106.685L111.797 136.954C109.8 138.952 106.564 138.952 104.567 136.954L87.9347 120.322C85.9373 118.324 85.9373 115.088 87.9347 113.091L89.2264 111.799C91.2239 109.801 94.4597 109.801 96.4572 111.799L108.182 123.524L133.543 98.1626C135.54 96.165 138.776 96.165 140.774 98.1626L142.065 99.4543C144.063 101.452 144.063 104.688 142.066 106.685Z'
                  fill='#38B8AB'
                />
              </g>
              <defs>
                <clipPath id='clip0_2701_18599'>
                  <rect
                    width='98.8596'
                    height='98.8596'
                    fill='white'
                    transform='translate(65.5703 65.5701)'
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          <Typography
            sx={{
              color: '#151515',
              fontWeight: '900',
              fontSize: '24px',
              fontFamily: 'Mona Sans',
              textAlign: 'center',
              width: '75%',
              textTransform: 'uppercase',
              lineHeight: '90%',
              letterSpacing: '1px',
            }}
          >
            Success!
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              textAlign: 'center',
              fontFamily: 'Mona Sans',
              fontWeight: 'bold',
            }}
          >
            What’s next?
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              textAlign: 'center',
              fontFamily: 'Mona Sans',
            }}
          >
            Download images of AIO CUSTOM™ and share it with the world!
          </Typography>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Button
              onClick={() => {
                props.setMode(false);
              }}
              sx={{
                color: 'white',
                backgroundColor: '#383A3A',
                fontSize: { xs: '10px', sm: '14px' },
                textAlign: 'center',
                fontFamily: 'Mona Sans',
                borderRadius: '100px',
                margin: '15px 15px',
                padding: '12px 20px',
                fontWeight: '700',
                lineHeight: 'normal',
                letterSpacing: ' 0.5px',
                textTransform: 'capitalize',
                zIndex: '1',
                cursor: 'pointer',
                position: 'relative',

                '&::after': {
                  content: '" "',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  zIndex: '-1',
                  display: 'block',
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                  borderRadius: '100px',
                  backgroundColor: '#383A3A',
                  transitionProperty:
                    'background-color, box-shadow,transform,filter',
                  transitionDuration: '0.5s',
                },
                '&:hover': {
                  '&::after': {
                    transform: 'scale(1.1)',
                    backgroundColor: '#383A3A',
                  },
                },
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Grow>
    </Box>
  );
}

export default SaveSuccessComp;
